import {
  Box,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  formatISO,
  isBefore,
  roundToNearestMinutes,
} from 'date-fns';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { Task } from '../../core/types/task';
import { PopConfirm } from '../../shared/components/popConfirm';
import {
  useGetAllProjectsQuery,
  useGetAllWorkersQuery,
  usePutTasksBodyMutation,
} from '../../core/redux/worker';
import { NOT_DELIVERED_COLOR } from '../../shared/constants';
import { UpdateTask } from '../../core/api/updateTask';
import { SelectItem } from '../../shared/types/util/selectItem';
import { SearchSelect } from '../../shared/components/searchSelect';
import { useOrganizations } from '../../core/redux/leftFilterState';
import { Button } from '../../shared/components/button';
import { useDummies } from '../../core/hooks/useDummies';

export const EditTask: FC<{
    task?: Task|undefined,
    onRemove?: (id: string) => Promise<Task>,
    onClose?: () => void,
  }> = ({
    task,
    onRemove,
    onClose = () => null,
  }) => {
    const [from, setFrom] = useState<Date>(new Date());
    const [to, setTo] = useState<Date>(new Date());
    const [project, setProject] = useState<SelectItem|undefined>(undefined);
    const [worker, setWorker] = useState<SelectItem|undefined>(undefined);
    const [workerType, setWorkerType] = useState<SelectItem|undefined>(undefined);
    const [isDummy, setIsDummy] = useState<boolean>(false);
    const [commitPercentage, setCommitPercentage] = useState<number>(100);
    const [comment, setComment] = useState<string>('');
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const dummies = useDummies();

    const organizations = useOrganizations();
    const { data: workers, isLoading: workersLoading } = useGetAllWorkersQuery({ organizations });
    const { data: projects, isLoading: projectsLoading } = useGetAllProjectsQuery({ organizations });
    const [sendUpdate, { isLoading: isUpdating }] = usePutTasksBodyMutation();

    useEffect(() => {
      if (!task) return;
      setFrom(roundToNearestMinutes(new Date(task.startTime), { nearestTo: 15 }));
      setTo(roundToNearestMinutes(new Date(task.endTime), { nearestTo: 15 }));
      if (task.project) {
        setProject({ id: task.project.id, label: `${task.project.id} - ${task.project.projectName}` });
      }
      if (task.worker) {
        setWorker({ id: task.worker?.employeeNumber, label: task.worker?.fullName });
        setWorkerType(undefined);
        setIsDummy(false);
      } else {
        setWorker(undefined);
        if (task.workerType) {
          setWorkerType({ id: task.workerType, label: task.workerType });
        }
        setIsDummy(true);
      }
      setCommitPercentage(task.commitPercentage ?? 100);
      setComment(task.comment || '');
    }, [task]);

    const deleteTask = () => {
      if (!task) return;
      if (!onRemove) return;
      setIsDeleting(true);
      onRemove(task.id).then(() => {
        onClose();
        setIsDeleting(false);
      });
    };

    const updateTask = () => {
      if (!task) return;
      if (!project) return;
      if (isDummy) {
        const data: UpdateTask = {
          workerType: workerType && workerType?.label,
          projectId: parseInt(`${project.id}`, 10),
          startTime: formatISO(from),
          endTime: formatISO(to),
          title: 'test',
          commitPercentage,
          comment,
        };
        sendUpdate({ id: task.id, data }).unwrap().then(() => {
          onClose();
        });
      } else {
        const data: UpdateTask = {
          workerId: worker && parseInt(`${worker.id}`, 10),
          projectId: parseInt(`${project.id}`, 10),
          startTime: formatISO(from),
          endTime: formatISO(to),
          title: 'test',
          commitPercentage,
          comment,
        };
        sendUpdate({ id: task.id, data }).unwrap().then(() => {
          onClose();
        });
      }
    };

    const periodValid = useMemo(() => {
      if (isBefore(from, to)) return true;
      return false;
    }, [from, to]);

    const formValid = useMemo(() => {
      if (isDummy) {
        if (!project || !workerType || !periodValid) return false;
      } else if (!project || !worker || !periodValid) return false;
      return true;
    }, [isDummy, project, worker, workerType, periodValid]);

    const projectList = useMemo(() => {
      if (!projects) return [];
      return projects.map((p) => ({ id: p.id, label: `${p.id} - ${p.projectName}` }))
        .sort((a, b) => a.label.localeCompare(b.label, 'nb'));
    }, [projects]);

    const workerList = useMemo(() => {
      if (!workers) return [];
      return workers.map((w) => ({ id: w.employeeNumber, label: w.fullName }))
        .sort((a, b) => a.label.localeCompare(b.label, 'nb'));
    }, [workers]);

    if (task && task.type !== 'Normal') return <span>Dette er ikke en oppgave!</span>;
    return (
      <div className="edit-task-component">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <DateTimePicker size="medium" fullWidth label="Fra" value={from} onChange={setFrom} defaultTime={7} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <DateTimePicker size="medium" fullWidth label="Til" value={to} onChange={setTo} minDate={from} defaultTime={15} />
            </Box>
          </Box>
          <Box>
            <SearchSelect
              label="Prosjekt"
              errorLabel="Velg et prosjekt"
              required
              loading={projectsLoading}
              value={project}
              onChange={(p) => setProject(p)}
            >
              {projectList}
            </SearchSelect>
          </Box>
          <Box display="flex" gap={2}>
            {isDummy ? (
              <SearchSelect
                label="Arbeidstype"
                errorLabel="Velg en type"
                loading={workersLoading}
                required
                value={workerType}
                onChange={(w) => setWorkerType(w)}
              >
                {dummies}
              </SearchSelect>
            ) : (
              <SearchSelect
                label="Ansatt"
                errorLabel="Velg en ansatt"
                required
                loading={workersLoading}
                value={worker}
                onChange={(w) => setWorker(w)}
              >
                {workerList}
              </SearchSelect>
            )}
            <TextField
              sx={{ width: 125 }}
              type="number"
              value={commitPercentage}
              onChange={(v) => setCommitPercentage(parseInt(v.target.value, 10))}
              onBlur={() => setCommitPercentage((v) => (Math.min(Math.max(v, 0), 100)))}
              InputProps={{
                inputProps: { min: 0, max: 100, step: 10 },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Box>
          <Box>
            <Button fullWidth variant="contained" onClick={() => setIsDummy(!isDummy)}>{`Endre til ${isDummy ? 'Ansatt' : 'Arbeidstype'}`}</Button>
          </Box>
          <Box>
            <TextField
              label="Kommentar"
              fullWidth
              multiline
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              minRows={4}
            />
          </Box>
          {!periodValid && (
            <Typography color={NOT_DELIVERED_COLOR} align="right">Fra tid må være før til tid</Typography>
          )}
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
              {task !== undefined && (
                <PopConfirm
                  buttonProps={{ disabled: isUpdating }}
                  loading={isDeleting}
                  content={(
                    <>
                      Vil du slette denne oppgaven?<br />
                      Det vil ikke være mulig å angre slettingen
                    </>
                  )}
                  onConfirm={deleteTask}
                />
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button color="primary" variant="outlined" disabled={isUpdating || isDeleting} onClick={onClose}>Avbryt</Button>
              <Button disabled={!formValid || isDeleting} color="primary" loading={isUpdating} variant="contained" onClick={task === undefined ? () => null : updateTask}>Lagre</Button>
            </Box>
          </Box>
        </Box>
      </div>
    );
  };
