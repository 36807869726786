import { SortOptions } from '../redux/leftFilterState';

export const translateErrorCode = (endPoint: string, errorText: string, errorCode = 500) => {
  if (errorText.includes('authentication')) {
    return 'Et autoriseringsproblem har oppstått';
  }
  if (errorCode === 403) {
    return 'En autoriseringsfeil har oppstått';
  }
  if (endPoint === 'moveTask') {
    return 'Kunne ikke flytte oppgave';
  }
  if (errorText === 'User not allowed to place task on worker') {
    return 'Du har ikke rettigheter til denne handlingen';
  }
  if (errorText === 'User cannot edit this task') {
    return 'Du har ikke rettigheter til denne handlingen';
  }
  return 'En feil har oppstått';
};

export const translateColumnName = (c: string, short: boolean = false) => {
  if (c === 'id') return 'ID';
  if (c === 'label') return 'Navn';
  if (c === 'responsible_id') return short ? 'Ans. ID' : 'Ansvarlig ID';
  if (c === 'responsible_name') return 'Ansvarlig navn';
  if (c === 'employmentType') return 'Ansettelsestype';
  if (c === 'department') return 'Avdeling';
  if (c === 'jobRole') return 'Rolle';
  if (c === 'leader') return 'Leder';
  if (c === 'tag') return 'Merke';
  return c;
};

export const translateSortOption = (o: SortOptions) => {
  switch (o) {
    case 'id': return 'ID';
    case 'mark': return 'Merke';
    case 'name': return 'Navn';
    case 'project': return 'Prosjekt';
    default: return 'Standard sortering';
  }
};
