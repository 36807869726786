import { useEffect, useState } from 'react';
import { NewTask } from '../api/newTask';
import {
  task2UpdateTask,
  task2MoveTask,
  newTask2Task,
  absence2Task,
  emptyProject,
} from '../helpers/converters';
import { useTimeFrameStrings } from './useTimeframe';
import { latestAssignment, generateTaskId } from '../helpers/taskHelpers';
import {
  useDeleteTaskMutation,
  useGetAllTasksQuery,
  useMoveTaskMutation,
  usePostCreateTaskMutation,
  usePutTasksBodyMutation,
  usePostAbsenceMutation,
} from '../redux/worker';
import { Task } from '../types/task';
import { UpdateTask } from '../api/updateTask';
import { MoveTask } from '../api/moveTask';

export const useTasks = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const timeframe = useTimeFrameStrings();
  const [updateReq, { isLoading: updateLoading }] = usePutTasksBodyMutation();
  const [moveReq, { isLoading: moveLoading }] = useMoveTaskMutation();
  const [deleteReq, { isLoading: deleteLoading }] = useDeleteTaskMutation();
  const [createReq, { isLoading: createLoading }] = usePostCreateTaskMutation();
  const [createAbsence, { isLoading: createAbsenceLoading }] = usePostAbsenceMutation();

  const updating = updateLoading
    || moveLoading
    || deleteLoading
    || createLoading
    || createAbsenceLoading;

  const {
    data: allTasksData,
    isLoading,
    refetch,
  } = useGetAllTasksQuery(timeframe, { pollingInterval: 30000, skip: updating });

  const updateTask = (task: Task) => {
    const updatedTasks = [task, ...(task.related || [])];
    setTasks((as) => {
      const newTasks = updatedTasks.filter((x) => as.find((y) => x.id === y.id) === undefined);
      return [
        ...as.map((a) => {
          const t = updatedTasks.find((x) => x.id === a.id);
          return t ? latestAssignment([t as Task, a]) : a;
        }),
        ...newTasks,
      ];
    });
  };

  const deleteTask = (delTask: Task|string) => {
    const id = typeof delTask === 'string' ? delTask : delTask.id;
    setTasks((old) => old.filter((o) => o.id !== id));
  };

  useEffect(() => { refetch(); }, []);

  useEffect(() => { if (allTasksData) setTasks(allTasksData); }, [allTasksData]);

  const update = (id: string, task: Partial<UpdateTask>) => {
    if (!id) return false;
    const old = tasks.find((a) => a.id === id);
    if (!old) return false;
    const data: UpdateTask = {
      ...task2UpdateTask(old),
      ...task,
    };
    return updateReq({ id, data });
  };

  const move = async (id: string, task: Partial<MoveTask>, tempTitle: string = '') => {
    if (!id) return false;
    const old = tasks.find((a) => a.id === id);
    if (!old) return false;

    const newTask: Task = {
      ...old,
      worker: old.worker ? { ...old.worker } : undefined,
      project: old.project ? { ...old.project } : undefined,
    };
    if (newTask.worker && task.workerId) {
      newTask.worker.employeeNumber = task.workerId;
      newTask.worker.fullName = tempTitle;
    }
    newTask.workerType = task.workerType || tempTitle;
    if (task.projectId) {
      if (!newTask.project) {
        newTask.project = emptyProject(task.projectId);
      } else {
        newTask.project.id = task.projectId;
      }
      newTask.project.projectName = tempTitle.replace(/^\d+ - /, '');
    }
    newTask.startTime = task.startTime || newTask.startTime;
    newTask.endTime = task.endTime || newTask.endTime;

    updateTask(newTask);

    let payload: Task | null;
    try {
      const body: MoveTask = {
        ...task2MoveTask(old),
        ...task,
      };
      payload = await moveReq({ id, body }).unwrap();
      updateTask(payload);
    } catch (e) {
      payload = null;
      updateTask(old);
    }
    return payload;
  };

  const add = async (task: NewTask, tempTitle: string = '') => {
    const id = generateTaskId();
    const newTask = newTask2Task(id, task);
    if (newTask.worker) {
      newTask.worker.fullName = tempTitle;
    } else {
      newTask.workerType = task.workerType || tempTitle;
    }
    if (newTask.project) newTask.project.projectName = tempTitle;
    newTask.status = task.type === 'Unavailability' ? 'Created' : 'New';
    updateTask(newTask);
    let payload: Task | null;
    try {
      if (task.type === 'Unavailability' && task.workerId) {
        payload = absence2Task(await createAbsence({
          workerId: task.workerId,
          body: { comment: task.comment ?? '', ...task },
        }).unwrap());
      } else {
        payload = await createReq(task).unwrap();
      }
      updateTask(payload);
    } catch (e) {
      payload = null;
    }
    deleteTask(id);
    return payload;
  };

  const remove = async (id: string) => {
    const res = await deleteReq(id).unwrap();
    deleteTask(id);
    return res;
  };

  return {
    tasks,
    isLoading,
    updating,
    refetch,
    update,
    move,
    add,
    remove,
  };
};
