import React, {
  FC,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { MenuItem } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AccountInfo } from '@azure/msal-browser';
import { msalInstance } from '../../shared/msal';
import { SearchSelect } from '../../shared/components/searchSelect';
import { SelectItem } from '../../shared/types/util/selectItem';
import { Modal } from '../../shared/components/modal';
import {
  useDeleteAliasMutation,
  useGetAliasQuery,
  useGetAllWorkersQuery,
  usePutAliasMutation,
} from '../../core/redux/worker';
import { Worker } from '../../core/types/worker';
import { stopPropagation } from '../../core/helpers/functions';
import { WorkerDetails } from '../workerDetails';
import { HasRoleTemplate } from '../hasRoleTemplate';
import { DepartmentSelector } from '../planner/partials/departmentSelector';
import { Header as SharedHeader } from '../../shared/components/header';
import { PageName } from '../../shared/types/util/pageName';

export const Header: FC<{
  page?: PageName,
  children?: ReactNode
}> = ({
  page,
  children,
}) => {
  const account: AccountInfo | null = msalInstance.getActiveAccount();

  const { data: users = [] as Worker[] } = useGetAllWorkersQuery();
  const { data: alias } = useGetAliasQuery(account?.username || skipToken);

  const [removeAlias] = useDeleteAliasMutation();
  const [updateAlias] = usePutAliasMutation();

  const [workerInfoOpen, setWorkerInfoOpen] = useState<boolean>(false);

  const none = { id: '', label: 'Ingen' };

  const userSelectItems = useMemo((): SelectItem[] => {
    const userItems = users
      .filter((u) => u.email !== undefined && u.email !== '')
      .map((u) => ({
        id: u.email as string,
        label: `${u.fullName} (${u.department})`,
      }));
    userItems.unshift(none);
    return userItems;
  }, [users]);

  const currentUserSelect = useMemo((): SelectItem => {
    if (alias) {
      return { id: alias.aliasId, label: alias.alias.fullName };
    }
    return none;
  }, [alias]);

  const changeAlias = (v: SelectItem | undefined) => {
    if (!account) return;
    if ((!v || v.id === '') && currentUserSelect.id !== '') {
      removeAlias(account.username).unwrap().then(() => {
        window.location.reload();
      });
      return;
    }
    if (!v || v.id === '') return;
    updateAlias({ id: account.username, target: `${v.id}` }).unwrap().then(() => {
      window.location.reload();
    });
  };

  const getInitials = () => {
    let nameArray: string[] | null = null;
    if (alias?.alias) {
      nameArray = alias.alias.fullName.split(' ');
    } else if (account?.name) {
      nameArray = account.name.split(' ');
    }
    const firstName = nameArray ? nameArray[0] : null;
    const lastName = nameArray ? nameArray[nameArray.length - 1] : null;
    if (!firstName || !lastName) { // can not get username
      return 'N/A';
    }
    return (firstName.charAt(0) + lastName.charAt(0));
  };

  return (
    <>
      <SharedHeader
        page={page}
        middleContent={<DepartmentSelector />}
        extraMenuItems={(
          <HasRoleTemplate roles="super-admin">
            <MenuItem onClick={stopPropagation} disableRipple disableTouchRipple>
              <SearchSelect
                label="Brukeralias"
                value={currentUserSelect}
                onChange={changeAlias}
              >
                {userSelectItems}
              </SearchSelect>
            </MenuItem>
            {alias?.alias && (
            <MenuItem onClick={() => setWorkerInfoOpen(true)}>
              Vis alias bruker
            </MenuItem>
            )}
          </HasRoleTemplate>
          )}
        initials={getInitials()}
        userIconColor={alias?.alias ? '#dd6f1a' : undefined}
      >
        {children}
      </SharedHeader>
      <HasRoleTemplate roles="super-admin">
        {workerInfoOpen && alias?.alias && (
        <Modal title={alias.alias.fullName} compactHeader onClose={() => setWorkerInfoOpen(false)}><WorkerDetails id={alias.alias.employeeNumber} /></Modal>
        )}
      </HasRoleTemplate>
    </>
  );
};
