import { Box, BoxProps } from '@mui/material';
import { Displayable } from '../../../shared/types/util/displayable';

export const TagButton = ({
  worker,
  defaultText = 'Sett merke',
  ...props
}: {
  worker: Displayable|undefined,
  defaultText?: string,
} & BoxProps) => (
  <Box
    display="flex"
    flex={1}
    sx={worker?.tag && { backgroundColor: worker.tag.background, color: worker.tag.foreground }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <span className="line-wrapper">
      <span className="one-line-ellipse">
        {worker?.tag ? worker.tag.label : defaultText}
      </span>
    </span>
  </Box>
);
