import {
  FC,
  ReactElement,
  useEffect,
} from 'react';
import './router.scss';
import {
  BrowserRouter as ReactRouter,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { WorkerPlanner } from './pages/workerPlannerPage';
import { ProjectPlanner } from './pages/projectPlannerPage';

import { AccessDeniedPage } from './pages/accessDenied';
import { useSetDefaultFilters } from './components/planner/hooks/useSetDefaultFilters';
import { urlCombine } from './shared/logic/functions';
import { config as machineConfig } from './shared/config/machine';
import { config as rentalConfig } from './shared/config/rental';
import { ExternalRedirect } from './components/externalRedirect';
import { WorkerPlannerPrintable } from './pages/workerPlannerPrintablePage';
import { ProjectStatusPage } from './pages/statusPage';
import { useCanAccessOperationStatus } from './shared/hooks/authorization';
import { Unauthenticated } from './shared/components/unauthenticated';
import { config } from './config';
import { useHasAppRole } from './shared/msal';

const Redirect: FC<{ to: string }> = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, []);
  return null;
};

const OperationStatusRoute = ({ children }: {children: ReactElement}) => {
  const { canAccessOperationStatus, isAuthenticated } = useCanAccessOperationStatus();

  if (!isAuthenticated) {
    return null;
  } if (canAccessOperationStatus) {
    return children || null;
  }
  return (
    <Redirect to="/ingentilgang" />
  );
};

export const Router = () => {
  const { hasAppRole, isAuthenticated } = useHasAppRole();
  useSetDefaultFilters();
  return (
    <div className="router-wrapper">
      <MsalAuthenticationTemplate
        authenticationRequest={{ scopes: [config.SCOPE] }}
        interactionType={InteractionType.Redirect}
        errorComponent={Unauthenticated}
      >
        <ReactRouter>
          <Routes>
            {isAuthenticated && hasAppRole && (
            <>
              <Route path="/ingentilgang" element={<AccessDeniedPage />} />
              <Route path="/mannskap/print" element={<WorkerPlannerPrintable />} />
              <Route path="/mannskap" element={<WorkerPlanner />} />
              <Route path="/prosjekt" element={<ProjectPlanner />} />
              <Route path="/status" element={<OperationStatusRoute><ProjectStatusPage /></OperationStatusRoute>} />
              <Route path="/maskin" element={<ExternalRedirect to={urlCombine(machineConfig.FRONTEND_URL, 'maskin')} />} />
              <Route path="/internutleie" element={<ExternalRedirect to={urlCombine(rentalConfig.FRONTEND_URL, 'internutleie')} />} />
              <Route path="/" element={<Redirect to="/prosjekt" />} />
            </>
            )}
            {isAuthenticated && !hasAppRole && (
              <Route path="*" element={<AccessDeniedPage />} />
            )}
          </Routes>
        </ReactRouter>
      </MsalAuthenticationTemplate>
    </div>
  );
};
