/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SelectItem } from '../../shared/types/util/selectItem';

const stateName = 'taskFilterState';

interface InitialState {
  project: {
    workers: SelectItem[],
  },
  worker: {
    projects: SelectItem[],
  },
}

const initialState: InitialState = {
  project: {
    workers: [],
  },
  worker: {
    projects: [],
  },
};

const taskFilterState = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setProjectWorkers(state, { payload: workers }: PayloadAction<SelectItem[]>) {
      state.project.workers = workers;
    },
    setWorkerProjects(state, { payload: projects }: PayloadAction<SelectItem[]>) {
      state.worker.projects = projects;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setProjectWorkers,
  setWorkerProjects,
  reset,
} = taskFilterState.actions;

export default persistReducer({ key: stateName, storage }, taskFilterState.reducer);
