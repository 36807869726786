import { useMemo } from 'react';
import { msalInstance } from '../../shared/msal';
import { useGetAllWorkersQuery } from '../../shared/redux/rental';

/**
 * Get the user object for the logged in user
 *
 * null if user list is not fetched yet,
 * undefined if user not found
 */
export const getLoggedInUserObject = () => {
  const { data: workers } = useGetAllWorkersQuery();
  const loggedInAccount = useMemo(() => (
    msalInstance.getActiveAccount()
  ), [msalInstance]);

  const loggedInUser = useMemo(() => {
    if (!workers) return null;
    return workers.find((e) => e.email === loggedInAccount?.username);
  }, [workers, loggedInAccount]);

  return loggedInUser;
};
