import { FC, ReactNode } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from '@mui/material';
import { PageName } from '../../../core/types/util/pageName';
import { PopMenu } from '../../../shared/components/popMenu';
import {
  setColumns,
  setProjectSearch,
  setWorkerSearch,
  useColumnNames,
  useColumns,
} from '../../../core/redux/leftFilterState';
import { translateColumnName } from '../../../core/helpers/translate';
import { PopMenuHeader } from '../../popMenuHeader';
import { useDispatch, useSelector } from '../../../core/hooks/redux';
import { FilterIcon } from '../../../shared/components/filterIcon';

export const LeftHeader: FC<{
  page: PageName,
  filters?: ReactNode
}> = ({
  page,
  filters,
}) => {
  const leftLeaderFilter = useSelector((l) => l.leftFilterState.worker.leaders) || [];
  const leftEmploymentTypeFilter = useSelector((l) => l.leftFilterState.worker.employmentTypes) || [];
  const leftJobRoleFilter = useSelector((l) => l.leftFilterState.worker.jobRoles) || [];
  const leftProjectIncludeInternalProduction = useSelector((s) => s.leftFilterState.project.includeInternalProduction) || false;
  const leftProjectIncludeOwnProjects = useSelector((s) => s.leftFilterState.project.includeOwnProjects) || false;
  const leftProjectShowOnlyPlannedProjects = useSelector((s) => s.leftFilterState.project.showOnlyPlannedProjects) || false;
  const leftManagerFilter = useSelector((s) => s.leftFilterState.project.managers) || [];

  const filterActive = (() => {
    if (page === 'worker') {
      return leftLeaderFilter.length > 0 || leftEmploymentTypeFilter.length > 0 || leftJobRoleFilter.length > 0;
    }
    if (page === 'project') {
      return leftProjectIncludeInternalProduction || leftProjectIncludeOwnProjects || leftProjectShowOnlyPlannedProjects || leftManagerFilter.length > 0;
    }
    return false;
  })();

  const columns = useColumnNames(page);
  const activeColumns = useColumns(page);
  const leftSearch = page === 'project'
    ? useSelector((s) => s.leftFilterState.project.search) || ''
    : useSelector((s) => s.leftFilterState.worker.search) || '';
  const dispatch = useDispatch();
  const toggleColumn = (column: string) => {
    const state = (activeColumns as string[]).includes(column);
    if (state) {
      dispatch(setColumns({ [page]: (activeColumns as string[]).filter((c) => c !== column) }));
    } else {
      dispatch(setColumns({ [page]: [...(activeColumns as string[]), column] }));
    }
  };

  return (
    <div className="left-header-partial">
      <span className="header-text">
        {page === 'project' ? 'Prosjekt' : 'Ansatt'}
      </span>
      <span>
        <Box display="flex" flex={1} gap={3} justifyContent="flex-end">
          <TextField
            sx={{
              width: leftSearch ? '60%' : '30%', justifyContent: 'center', ':focus-within': { width: '60%', textAlign: 'left' }, transition: 'width 0.3s',
            }}
            placeholder="Søk"
            type="search"
            variant="standard"
            size="small"
            value={leftSearch}
            onChange={(e) => dispatch(page === 'project'
              ? setProjectSearch(e.target.value)
              : setWorkerSearch(e.target.value))}
          />
          <PopMenu
            button={(
              <IconButton color="primary" aria-label="showWeekend" component="span">
                <FilterIcon filterActive={filterActive} />
              </IconButton>
          )}
            width={filters === undefined ? '150px' : '225px'}
          >
            <Box display="flex" flexDirection="column" gap={0.5}>
              {filters !== undefined && (
              <span>
                <PopMenuHeader>Filter</PopMenuHeader>
                {filters}
              </span>
              ) }
              <Box>
                <PopMenuHeader>Kolonner</PopMenuHeader>
                {columns.map((column) => (
                  <FormGroup key={column}>
                    <FormControlLabel
                      label={translateColumnName(column)}
                      checked={(activeColumns as string[]).includes(column)}
                      onChange={() => { toggleColumn(column); }}
                      control={<Checkbox />}
                    />
                  </FormGroup>
                ))}
              </Box>
            </Box>
          </PopMenu>
        </Box>
      </span>
    </div>
  );
};
