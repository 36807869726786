import { EnvironmentName } from './shared/types/enums/environmentName';

export const getEnvironmentName = (env?: string): EnvironmentName => {
  const url = new URL(env || document.baseURI);
  if (url.hostname === 'localhost') return 'local';
  if (url.hostname === 'black-mushroom-096278803.1.azurestaticapps.net' && url.protocol === 'https:') return 'dev';
  if (url.hostname === 'mannskap-test.tsmaskin.no' && url.protocol === 'https:') return 'test';
  if (url.hostname === 'mannskap.tsmaskin.no' && url.protocol === 'https:') return 'prod';
  return '';
};
