import { useMemo } from 'react';
import { format, isValid } from 'date-fns';
import { useUrl } from '../../shared/hooks/params';
import { PageName } from '../types/util/pageName';

/** Get and switch the current date
 *
 * Switching dates only works if the page parameter is provided
 */
export const useDate = (
  /** Must be provided to switch date */
  page?: PageName,
) => {
  const [{ date: urlDate }, change] = useUrl();

  const setParams = (params: Record<string, string>) => {
    const urls: {[K in PageName]: string;} = {
      project: '/prosjekt',
      worker: '/mannskap',
      status: '/status',
    };
    if (page) {
      change(urls[page], params);
    }
  };

  const date = useMemo(() => {
    if (urlDate) {
      const d = new Date(urlDate);
      if (isValid(d)) {
        return d;
      }
    }
    return new Date();
  }, [urlDate]);

  const setDate = (d: Date) => setParams({ date: format(d, 'yyyy-MM-dd') });

  return [date, setDate] as [Date, (d: Date) => void];
};
