import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import {
  FC, useEffect, useState,
} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import { Header } from '../../components/header';
import {
  dateFormatWeek, TimelineHeaderNav, viewPeriodWeek,
} from '../../components/planner/partials/timelineHeader';
import { useDate } from '../../core/hooks/useDate';
import { Page } from '../../shared/components/page';
import { useOrganizations } from '../../core/redux/leftFilterState';
import {
  useDeleteTemplate,
  useGetWeeklyStatusQuery,
  useMoveTemplateAfterMutation,
  useMoveTemplateBeforeMutation,
  usePostAppendRowMutation,
  usePutUpdateTemplateMutation,
  useUpdateWeeklyStatusMutation,
  useUpdateWeeklyStatusRowMutation,
} from '../../core/redux/worker';
import { WeeklyStatusOrg, WeeklyStatusRow } from '../../core/types/weeklyStatus';
import './style.scss';

const StatusRow: FC<{
  editLayout: boolean;
  disabled: boolean;
  first: boolean;
  last: boolean;
  row: WeeklyStatusRow;
  updateRowComment: (id: string, oldcomment: string, comment: string) => void;
  updateRowName: (id: string, oldName: string, name: string) => void;
  deleteRow: (id: string) => void;
  moveUp: (id: string) => void;
  moveDown: (id: string) => void;
}> = ({
  editLayout,
  disabled,
  first,
  last,
  row,
  updateRowComment,
  updateRowName,
  deleteRow,
  moveUp,
  moveDown,
}) => {
  const [comment, setComment] = useState<string>(row.comment);
  const [name, setName] = useState<string>(row.name);
  useEffect(() => {
    setComment(row.comment);
    setName(row.name);
  }, [row]);

  return (
    <TableRow className={last ? 'last-row' : ''}>
      <TableCell />
      <TableCell
        sx={{
          verticalAlign: 'top',
        }}
      >
        {!editLayout && (
        <Box component="span" sx={{ whiteSpace: 'nowrap', lineHeight: '40px' }}>{row.name}</Box>
        )}
        {editLayout && (
        <TextField
          size="small"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          onBlur={() => updateRowName(row.templateId, row.name, name)}
          fullWidth
          sx={{
            minWidth: '200px',
          }}
        />
        )}
      </TableCell>
      <TableCell>
        <Box flexDirection="row" display="flex">
          <TextField
            multiline
            fullWidth
            size="small"
            disabled={editLayout || disabled}
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
            onBlur={() => updateRowComment(row.templateId, row.comment, comment)}
          />
          {editLayout && (
          <>
            <IconButton arial-label="up" onClick={() => moveUp(row.templateId)} disabled={first}>
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton arial-label="up" onClick={() => moveDown(row.templateId)} disabled={last}>
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton arial-label="delete" onClick={() => deleteRow(row.templateId)}>
              <DeleteIcon />
            </IconButton>
          </>
          )}
        </Box>
      </TableCell>
    </TableRow>

  );
};

const StatusRows: FC<{
  projectStatus: WeeklyStatusOrg,
  editLayout: boolean,
  disabled: boolean,
  onFetch: () => void,
}> = ({
  projectStatus,
  editLayout,
  disabled,
  onFetch = () => {},
}) => {
  const [date] = useDate('status');
  const [append] = usePostAppendRowMutation();
  const [updateTemplate] = usePutUpdateTemplateMutation();
  const [deleteTemplate] = useDeleteTemplate();
  const [moveTemplateBefore] = useMoveTemplateBeforeMutation();
  const [moveTemplateAfter] = useMoveTemplateAfterMutation();
  const [updateComment] = useUpdateWeeklyStatusMutation();
  const [updateRowComment] = useUpdateWeeklyStatusRowMutation();

  const updateGeneralComment = (orgId: number, oldComment: string, comment: string) => {
    if (oldComment === comment) return;
    updateComment(date, orgId, comment);
  };
  const appendRow = (id: number) => {
    onFetch();
    append(date, id);
  };

  const updateRow = (id: string, oldcomment: string, comment: string) => {
    if (oldcomment === comment) return;
    updateRowComment(date, id, comment);
  };

  const updateRowName = (id: string, oldName: string, name: string) => {
    if (oldName === name) return;
    updateTemplate({ id, name });
  };

  const deleteRow = (id: string) => {
    onFetch();
    deleteTemplate(date, id);
  };

  const moveUp = (id: string) => {
    const idx = projectStatus.rows.findIndex((x) => x.templateId === id);
    if (idx < 1) return;
    onFetch();
    const otherId = projectStatus.rows[idx - 1].templateId;
    moveTemplateBefore({ id, otherId });
  };

  const moveDown = (id: string) => {
    const idx = projectStatus.rows.findIndex((x) => x.templateId === id);
    if (idx + 1 >= projectStatus.rows.length) return;
    onFetch();
    const otherId = projectStatus.rows[idx + 1].templateId;
    moveTemplateAfter({ id, otherId });
  };

  const [comment, setComment] = useState<string>('');
  useEffect(() => {
    setComment(projectStatus.comment);
  }, [projectStatus]);

  return (
    <>
      <TableRow
        className={(projectStatus.rows.length === 0 && !editLayout) ? 'last-row' : ''}
        key={projectStatus.id}
      >
        <TableCell
          sx={{
            verticalAlign: 'top',
          }}
        >
          <Box component="span" sx={{ whiteSpace: 'nowrap', lineHeight: '40px' }}>{projectStatus.name}</Box>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Box component="span" sx={{ whiteSpace: 'nowrap', lineHeight: '40px' }}>Generelt</Box>
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            multiline
            disabled={editLayout || disabled}
            size="small"
            value={comment}
            onChange={(e) => { setComment(e.currentTarget.value); }}
            onBlur={() => updateGeneralComment(projectStatus.id, projectStatus.comment, comment)}
          />
        </TableCell>
      </TableRow>
      {projectStatus.rows.map((row, idx, rows) => (
        <StatusRow
          key={`${row.templateId}`}
          disabled={disabled}
          editLayout={editLayout}
          row={row}
          first={idx === 0}
          last={(idx === rows.length - 1) && !editLayout}
          updateRowComment={updateRow}
          updateRowName={updateRowName}
          deleteRow={deleteRow}
          moveDown={moveDown}
          moveUp={moveUp}
        />
      ))}
      {editLayout && (
      <TableRow className="last-row">
        <TableCell />
        <TableCell />
        <TableCell>
          <IconButton onClick={() => appendRow(projectStatus.id)}>
            <AddIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      )}
    </>
  );
};

export const ProjectStatusPage = () => {
  const [date, setDate] = useDate('status');
  const [editLayout, setEditLayout] = useState<boolean>(false);
  const organizations = useOrganizations();
  const { data: weeklyStatus, isFetching } = useGetWeeklyStatusQuery(date);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);

  // Remove spinner when fetching is done
  useEffect(() => {
    if (showSpinner && !isFetching) { setShowSpinner(isFetching); }
  }, [isFetching]);

  useEffect(() => {
    setShowSpinner(isFetching); // Show spinner when needing to fetch data after changing date.
    setEditLayout(false);
  }, [date]);

  return (
    <Page className="transportplanlegger-page">
      <Header page="status" />
      <Box sx={{ padding: '10px' }}>
        <div className="timeline-header-component">
          <TimelineHeaderNav
            dateFormat={dateFormatWeek}
            viewPeriod={viewPeriodWeek}
            onDayChange={setDate}
            day={date}
          />
          { !editLayout && (
          <Button
            disabled={!weeklyStatus?.editable}
            variant="contained"
            onClick={() => setEditLayout(true)}
          >Endre layout
          </Button>
          )}
          { weeklyStatus?.editable && editLayout && (
          <Button
            variant="contained"
            onClick={() => setEditLayout(false)}
          >Lagre layout
          </Button>
          )}

        </div>
        <TableContainer sx={{
          position: 'fixed', left: 0, right: 0, bottom: 0, top: '124px',
        }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell width="1"><Typography variant="h6">Prosjekteier</Typography></TableCell>
                <TableCell width="1"><Typography variant="h6">Driftsleder</Typography></TableCell>
                <TableCell width="0"><Typography variant="h6">Kommentar</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {weeklyStatus && weeklyStatus?.organizations.filter((x) => organizations.length === 0 || organizations.includes(x.id)).map((top) => (
                <StatusRows
                  key={top.id}
                  projectStatus={top}
                  editLayout={editLayout}
                  disabled={!weeklyStatus.editable}
                  onFetch={() => setShowSpinner(true)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Backdrop open={showSpinner}>
        <CircularProgress
          size={75}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
            zIndex: 1000,
          }}
        />
      </Backdrop>

    </Page>
  );
};
