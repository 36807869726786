import { FC, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { isBefore } from 'date-fns';
import { Worker } from '../../../core/types/worker';
import { Loading } from './Loading';
import { useDate } from '../../../core/hooks/useDate';
import { EditAbsence } from '../../editAbsence';
import { Modal } from '../../../shared/components/modal';
import { format } from '../../../shared/logic/dates';

export const Details: FC<{
    worker: Worker|undefined,
    isLoading: boolean,
    cardNumber?: string;
  }> = ({
    worker,
    isLoading = false,
    cardNumber,
  }) => {
    const [absenceModalOpen, setAbsenceModalOpen] = useState<boolean>(false);
    const [date] = useDate();
    const closeModal = () => setAbsenceModalOpen(false);

    if (!worker || isLoading) return <Loading />;

    return (
      <div className="worker-details">
        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.mobilePhoneWork ? 'Ingen data' : worker.mobilePhoneWork}
            label="Telefon"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.email ? 'Ingen data' : worker.email}
            label="Epost"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.department ? 'Ingen data' : worker.department}
            label="Avdeling"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.leader ? 'Ingen data' : worker.leader}
            label="Leder"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.jobType ? 'Ingen data' : worker.jobType}
            label="Arbeid"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.jobRole ? 'Ingen data' : worker.jobRole}
            label="Rolle"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          {new Date(worker.dateOfEmployment) > new Date() ? (
            <TextField
              fullWidth
              value={format(new Date(worker.dateOfEmployment), 'dd.MM.yyyy')}
              label="Startdato"
              variant="standard"
              multiline
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              InputLabelProps={{ sx: { fontSize: 17 } }}
            />
          ) : (
            <TextField
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          )}
          <TextField
            fullWidth
            value={cardNumber || ' '}
            label="HMS kortnummer"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          {worker.lastEmploymentDay !== '' && (
            <TextField
              fullWidth
              value={format(new Date(worker.lastEmploymentDay), 'dd.MM.yyyy')}
              label="Sluttdato"
              variant="standard"
              multiline
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              InputLabelProps={{ sx: { fontSize: 17 } }}
            />
          )}
        </div>

        <div className="footer">
          <Button variant="outlined" onClick={() => setAbsenceModalOpen(true)}>Opprett fravær</Button>
        </div>
        {absenceModalOpen && (
          <Modal title={`Opprett fravær for ${worker.fullName} (${worker.employeeNumber})`} maxWidth="sm" onClose={closeModal}>
            <EditAbsence userId={worker.employeeNumber} onClose={closeModal} startDate={isBefore(date, new Date()) ? new Date() : date} />
          </Modal>
        )}
      </div>
    );
  };
