import React, { FC } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { AssignmentType } from '../../types/enums/assignmentType';
import { AssignmentStatus } from '../../types/enums/assignmentStatus';
import { getOrderStatusColor, getOrderStatusType } from '../../logic/translate';
import './style.scss';

export const MachineOrderStatusIcon: FC<{currStatus: AssignmentStatus, type: AssignmentType}> = ({ currStatus, type }) => {
  const statusType = getOrderStatusType(currStatus, type);
  const statusColor = getOrderStatusColor(currStatus, type);
  return (
    <div className="align-icon">
      <CircleIcon className={statusColor} />
      {statusType}
    </div>
  );
};
