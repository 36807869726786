import {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import {
  addHours,
  addYears,
  areIntervalsOverlapping,
  format,
  formatISO,
  isBefore,
  startOfHour,
  subHours,
  subYears,
} from 'date-fns';
import { toast } from 'react-toastify';
import { TaskEvent } from '../../core/types/taskEvent';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { SelectItem } from '../../shared/types/util/selectItem';
import { NOT_DELIVERED_COLOR } from '../../shared/constants';
import {
  useGetMyRolesQuery,
  useDeleteOrderMutation,
  useGetAllMachineAssignmentsQuery,
  useGetCategoriesQuery,
  usePostMachineOrderMutation,
  usePutMoveMachineOrderMutation,
} from '../../shared/redux/machine';
import { NewMachineOrder } from '../../shared/types/api/newMachineOrder';
import { Displayable } from '../../shared/types/util/displayable';
import { MoveMachineOrder } from '../../shared/types/api/moveMachineOrder';
import { Assignment } from '../../shared/types/assignment';
import { SearchSelect } from '../../shared/components/searchSelect';
import { PopConfirm } from '../../shared/components/popConfirm';
import './style.scss';
import { ensureAfter } from '../../shared/logic/dates';
import { Machine } from '../../shared/types/machine';

export const MachineOrderModal: FC<{
  event?: TaskEvent;
  assignment?: Assignment;
  projects: Displayable[];
  machines?: Machine[];
  isEdit?: boolean;
  refetch: () => void;
  onClose?: () => void;
}> = ({
  event,
  assignment,
  projects,
  machines,
  isEdit = false,
  refetch = () => null,
  onClose = () => null,
}) => {
  const { data: machineDummies = [] } = useGetCategoriesQuery({
    includeSubCategories: true,
  });
  const [updateOrder] = usePutMoveMachineOrderMutation();
  const [post] = usePostMachineOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [spinner, setSpinner] = useState<boolean>(false);
  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(new Date());
  const [project, setProject] = useState<SelectItem | undefined>();
  const [machine, setMachine] = useState<SelectItem | undefined>();
  const [subCat, setSubCat] = useState<SelectItem | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const mainCat = assignment?.category || event?.workerType || '';
  const { data: allAssignments } = useGetAllMachineAssignmentsQuery({
    from: formatISO(subYears(startOfHour(new Date()), 1)),
    to: formatISO(addYears(startOfHour(new Date()), 1)),
  });

  const canEdit = !assignment || isEdit;
  const { data: machineRoles } = useGetMyRolesQuery();

  const isNewOrder = assignment === undefined;
  const canOrderMachine = machineRoles?.some(
    (x) => x === 'prosjekteier' || x === 'maskin-koordinator',
  )
    && (isNewOrder
      || assignment?.type === 'Order'
      || (assignment?.order?.type === 'New' && assignment?.status !== 'Approved'));

  const subCategories = machineDummies.find((m) => m.name === mainCat)
    ?.subCategories
    ? (machineDummies
      .find((m) => m.name === mainCat)
      ?.subCategories.map((s) => ({ id: s, label: s })) as SelectItem[])
    : [];

  const machineList = useMemo(() => {
    if (!machines) return [];
    const items = machines
      .filter((m) => !mainCat || m.mainCategoryName === mainCat)
      .sort((a, b) => {
        const aCat = a.subCategoryName === subCat?.label
          ? `a${a.subCategoryName}`
          : `b${a.subCategoryName}`;
        const bCat = b.subCategoryName === subCat?.label
          ? `a${b.subCategoryName}`
          : `b${b.subCategoryName}`;
        return aCat.localeCompare(bCat, 'nb');
      })
      .map((m) => {
        const driver = m?.assignedDriver?.fullName
          ? ` - ${m.assignedDriver.fullName}`
          : '';
        return {
          id: m.internalNumber,
          label: `${m.internalNumber} - ${m.subCategoryName} - ${m.modelName}${driver}`,
        };
      }) as SelectItem[];
    return items;
  }, [machines, mainCat, subCat]);

  useEffect(() => {
    if (assignment) {
      setFrom(new Date(assignment.from));
      setTo(new Date(assignment.to));
      setProject({
        id: assignment.project.id,
        label: `${assignment.project.id} - ${assignment.project.projectName}`,
      });
      const driver = assignment.machine?.assignedDriver?.fullName
        ? ` - ${assignment.machine.assignedDriver.fullName}`
        : '';
      setMachine(
        assignment.machine
          ? {
            id: assignment.machine.internalNumber,
            label: `${assignment.machine.internalNumber} - ${assignment.machine.subCategoryName} - ${assignment.machine.modelName}${driver}`,
          }
          : undefined,
      );
      setSubCat({ id: assignment.subCategory, label: assignment.subCategory });
      setComment(assignment.orderComment ?? assignment.order?.comment);
    } else if (event) {
      if (subCategories.some((s) => s.label === event.title)) {
        setSubCat({
          id: event.title || event.id,
          label: event.title || event.id,
        });
      }
      const defaultProject = {
        id: event.parent,
        label: `${event.parent} - ${
          projects.find((p) => p.id === event.parent)?.label
        }`,
      };
      setFrom(addHours(event.startTime, 7));
      setTo(subHours(event.endTime, 9));
      setProject(defaultProject);
      setComment(undefined);
      setIsChanged(true);
    }
  }, [assignment, event]);

  const projectList: SelectItem[] = projects.map((p) => ({
    id: p.id,
    label: `${p.id} - ${p.label}`,
  }));

  const buttonConfirmText = useMemo(() => {
    if (assignment) return 'Lagre';
    return 'Send bestilling';
  }, [assignment]);

  const periodValid = useMemo(() => {
    if (isBefore(from, to)) return true;
    return false;
  }, [from, to]);

  const formValid = useMemo(() => {
    if (assignment) {
      if (!periodValid || !project || !mainCat || !subCat) return false;
    } else if (!event || !periodValid || !project || !mainCat || !subCat) return false;
    return true;
  }, [event, assignment, periodValid, project, mainCat, subCat, machine]);

  const orderUpdate = () => {
    if (!isEdit || !assignment || !project || !mainCat || !subCat || !subCat) return;
    const body: MoveMachineOrder = {
      from: formatISO(from),
      to: formatISO(to),
      category: mainCat,
      subCategory: subCat?.label,
      projectId: project.id as number,
      machineInternalNumber: machine?.id.toString(),
      comment: comment || '',
    };
    updateOrder({ id: assignment?.order?.id || assignment.id, body })
      .unwrap()
      .then(() => {
        refetch();
        setSpinner(false);
        onClose();
      })
      .catch(() => {
        toast.error('Kunne ikke oppdatere bestilling');
        setSpinner(false);
      });
  };

  const orderDelete = () => {
    if (!assignment) return;
    setSpinner(true);
    deleteOrder(assignment?.order?.id || assignment.id)
      .unwrap()
      .then(() => {
        refetch();
        setSpinner(false);
        onClose();
      })
      .catch(() => {
        setSpinner(false);
        toast.error('Kunne ikke kansellere bestilling');
      });
  };

  const send = () => {
    if (!event || !mainCat || !subCat || !subCat) return;
    const body: NewMachineOrder = {
      from: formatISO(from),
      to: formatISO(to),
      category: mainCat,
      subCategory: subCat.label,
      projectId: event.parent as number,
      machineInternalNumber: machine?.id.toString(),
      comment,
    };
    post(body)
      .unwrap()
      .then(() => {
        refetch();
        setSpinner(false);
        onClose();
      })
      .catch(() => {
        setSpinner(false);
        toast.error('Kunne ikke opprette bestilling');
      });
  };

  const confirmHandler = (v: string) => {
    setSpinner(true);
    if (assignment) {
      if (v === 'Lagre') return orderUpdate();
    }
    return send();
  };

  const overlapMessage = useMemo(() => {
    if (!machine || !allAssignments) {
      return null;
    }
    const overlappingEvents = [];
    if (assignment?.type === 'Assignment') {
      overlappingEvents.push(
        allAssignments.filter(
          (a) => assignment.id !== a.id
            && a.machine?.internalNumber === machine.id
            && areIntervalsOverlapping(
              { start: new Date(a.from), end: new Date(a.to) },
              { start: from, end: to },
            ),
        ),
      );
    } else {
      overlappingEvents.push(
        allAssignments.filter(
          (a) => a.machine?.internalNumber === machine.id
            && areIntervalsOverlapping(
              { start: new Date(a.from), end: new Date(a.to) },
              { start: from, end: to },
            ),
        ),
      );
    }
    if (overlappingEvents.length !== 0) {
      return overlappingEvents[0].map((e) => (
        <span key={e.id} className="event">
          {format(new Date(e.from), 'dd.MM.yyyy')} -{' '}
          {format(new Date(e.to), 'dd.MM.yyyy')} (prosjekt: {e.project.id} -{' '}
          {e.project.projectName})
        </span>
      ));
    }
    return null;
  }, [from, to, allAssignments, assignment, machine]);

  const isOverlap = useMemo(() => {
    if (!machine || !allAssignments) return false;
    if (assignment?.type === 'Assignment') {
      return allAssignments.some(
        (a) => assignment.id !== a.id
          && a.machine?.internalNumber === machine.id
          && areIntervalsOverlapping(
            { start: new Date(a.from), end: new Date(a.to) },
            { start: from, end: to },
          ),
      );
    }
    return allAssignments.some(
      (a) => a.machine?.internalNumber === machine.id
        && areIntervalsOverlapping(
          { start: new Date(a.from), end: new Date(a.to) },
          { start: from, end: to },
        ),
    );
  }, [from, to, machine, allAssignments, assignment]);

  return (
    <div className="edit-task-component">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          paddingTop: 1,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              format="dd.MM.yyyy"
              disableTime
              disabled={!canEdit}
              size="medium"
              fullWidth
              label="Fra"
              value={from}
              onChange={(d) => {
                setTo(ensureAfter(to, d));
                setFrom(d);
                setIsChanged(true);
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              format="dd.MM.yyyy"
              disableTime
              disabled={!canEdit}
              size="medium"
              fullWidth
              label="Til"
              value={to}
              onChange={(d) => {
                setTo(d);
                setIsChanged(true);
              }}
              minDate={from}
            />
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <SearchSelect
            label="Prosjekt"
            errorLabel="Velg et prosjekt"
            disabled={!canEdit}
            required
            value={project}
            onChange={(p) => {
              setProject(p);
              setIsChanged(true);
            }}
          >
            {projectList}
          </SearchSelect>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {!assignment?.machine && (
              <>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    label="Hovedkategori"
                    variant="outlined"
                    disabled
                    value={mainCat || ''}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Underkategori"
                    errorLabel="Velg en kategori"
                    required
                    disabled={!canEdit}
                    value={subCat}
                    onChange={(p) => {
                      setSubCat(p);
                      setMachine(undefined);
                      setIsChanged(true);
                    }}
                  >
                    {subCategories}
                  </SearchSelect>
                </Box>
              </>
            )}
          </Box>
          {(!!assignment?.machine || canOrderMachine) && (
            <Box sx={{ flex: 1 }}>
              <SearchSelect
                label="Maskin"
                value={machine}
                disabled={!canOrderMachine}
                onChange={(p) => {
                  setMachine(p);
                  setIsChanged(true);
                }}
              >
                {machineList}
              </SearchSelect>
            </Box>
          )}

          {isOverlap && (
            <Box className="overlapMessage">
              <span className="title">
                Maskin overlapper denne perioden fra:{' '}
              </span>
              {overlapMessage}
            </Box>
          )}
          <Box>
            <TextField
              label="Bestillingskommentar"
              fullWidth
              multiline
              disabled={!canEdit}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setIsChanged(true);
              }}
              minRows={4}
            />
          </Box>
        </Box>

        {!periodValid && (
          <Typography color={NOT_DELIVERED_COLOR} align="right">
            Fra tid må være før til tid
          </Typography>
        )}

        <Box color="#666666" display="flex" flexDirection="column">
          {assignment?.lastEditTime && (
            <span>
              Sist endret{' '}
              {assignment?.lastEditTime
                ? format(
                  new Date(assignment.lastEditTime),
                  "dd.MM.yyyy 'kl.' HH:mm",
                )
                : assignment?.createdTimestamp
                  && format(
                    new Date(assignment.createdTimestamp),
                    "dd.MM.yyyy 'kl.' HH:mm",
                  )}
              {assignment?.lastEditedByName
                ? ` av ${assignment.lastEditedByName}`
                : assignment?.createdByName
                  && ` av ${assignment.createdByName}`}
            </span>
          )}
          {assignment?.approvedTime && (
            <span>
              Godkjent{' '}
              {assignment?.approvedTime
                ? format(
                  new Date(assignment.approvedTime),
                  "dd.MM.yyyy 'kl.' HH:mm",
                )
                : assignment?.lastEditTime
                  && format(
                    new Date(assignment.lastEditTime),
                    "dd.MM.yyyy 'kl.' HH:mm",
                  )}
              {assignment?.approvedByName
                ? ` av ${assignment.approvedByName}`
                : assignment?.lastEditedByName
                  && ` av ${assignment.lastEditedByName}`}
            </span>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: canEdit ? 'space-between' : 'flex-end',
          }}
        >
          <Box display="flex" gap={2}>
            {assignment && assignment.status !== 'Approved' && isEdit && (
              <PopConfirm
                confirmText="Kanseller"
                buttonText="Kanseller"
                buttonColor="primary"
                buttonVariant="outlined"
                buttonProps={{ disabled: spinner }}
                content={(
                  <>
                    Vil du kansellere denne bestillingen?
                    <br />
                    Det vil ikke være mulig å angre
                  </>
                )}
                onConfirm={orderDelete}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              disabled={spinner}
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Lukk
            </Button>
            {isEdit && (
              <Button
                variant="contained"
                value={buttonConfirmText}
                disabled={!formValid || spinner || !isChanged}
                onClick={(e) => confirmHandler(e.currentTarget.value)}
              >
                {spinner ? <CircularProgress size={24} /> : buttonConfirmText}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
