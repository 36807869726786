import {
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FullC from '@fullcalendar/react'; // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, {
  EventReceiveArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import nbLocale from '@fullcalendar/core/locales/nb';
import {
  addDays,
  addHours,
  areIntervalsOverlapping,
  differenceInMinutes,
  endOfYear,
  formatISO,
  getHours,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subWeeks,
  Interval,
} from 'date-fns';
import {
  Box,
  CircularProgress,
  Drawer,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  EventApi,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import { ColSpec } from '@fullcalendar/resource';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Sidebar } from './partials/sidebar';
import { TimelineHeader } from './partials/timelineHeader';
import { useView } from '../../core/hooks/useView';
import { useDate } from '../../core/hooks/useDate';
import { PageName } from '../../core/types/util/pageName';
import { useDraggable } from './hooks/useDraggable';
import { TaskEvent } from '../../core/types/taskEvent';
import { eventRecieve2TaskEvent } from '../../core/helpers/converters';
import { LeftHeader } from './partials/leftHeader';
import { useColumns } from '../../core/redux/leftFilterState';
import { HoverEvent } from '../hoverEvent';
import { Event } from '../../core/types/util/event';
import { RootState } from '../../core/redux';
import { useGetCalendarQuery, useGetTaskQuery } from '../../core/redux/worker';
import { translateColumnName } from '../../core/helpers/translate';
import { startOfWeek } from '../../shared/logic/dates';
import { useDefaultUrlParams } from './hooks/useDefaultUrlParams';
import { TaskModal } from './partials/taskModal';
import { CalendarView } from '../../core/types/util/calendarView';
import { useTimeframe } from '../../core/hooks/useTimeframe';
import { Displayable } from '../../shared/types/util/displayable';
import { leftPad } from '../../shared/logic/functions';
import { Task } from '../../core/types/task';
import {
  shadowEvent,
  setShadow,
  clearShadow,
} from '../../shared/logic/shadowEvent';
import { Modal } from '../../shared/components/modal';
import {
  useGetAllMachinesQuery,
} from '../../shared/redux/machine';
import { Assignment } from '../../shared/types/assignment';
import { ExpiredCert } from '../../shared/components/certification/expiredCert';
import { MachineOrderStatusIcon } from '../../shared/components/machineOrderStatusIcon';
import { MachineOrderModal } from '../machineOrderModal/machineOrderModal';
import { EventCommentIcon } from '../../shared/components/eventCommentIcon';
import { TagColumn } from '../tagColumn';
import { EquipmentAssignment } from '../../shared/types/equipmentAssignment';
import './style.scss';
import { EquipmentOrderModal } from '../equipmentOrderModal/equipmentOrderModal';
import { SplitModal, SplitMode } from './partials/splitModal';
import { useSorter } from './hooks/useSorter';
import { MissingSecTraining } from '../../shared/components/securityTraining/missingSecTraining';

type MoveOrCopyEvent = EventResizeDoneArg | EventDropArg;

export const Planner: FC<{
  onEventChanged?: (
    event: TaskEvent,
    reset: () => void,
    split?: boolean
    ) => void;
  onEventAdded?: (
    event: TaskEvent,
    view: CalendarView,
    reset: () => void,
    copied?: boolean,
    split?: boolean,
  ) => void;
  onEventRemoved?: (id: string) => Promise<Task>;
  onLeftSelect?: (id: number, title: string) => void;
  onRightSelect?: (id: number | string, title: string) => void;
  refetchMachines?: () => void;
  refetchEquipment?: () => void;
  page: PageName;
  tasks: Event[];
  machines?: Assignment[];
  equipment?: EquipmentAssignment[];
  left: Displayable[];
  right: Displayable[];
  fetching?: boolean;
  taskLoading?: boolean;
  leftLoading?: boolean;
  rightLoading?: boolean;
  leftFilters?: ReactNode;
  rightFilters?: ReactNode;
  taskFilters?: { value: ReactNode; active: boolean }
}> = ({
  onEventChanged = () => null,
  onEventAdded = () => null,
  onLeftSelect = () => null,
  onRightSelect = () => null,
  onEventRemoved,
  refetchMachines = () => null,
  refetchEquipment = () => null,
  page,
  tasks,
  machines,
  equipment,
  left,
  right,
  fetching = false,
  taskLoading = false,
  leftLoading = false,
  rightLoading = false,
  leftFilters,
  rightFilters,
  taskFilters,
}) => {
  useDefaultUrlParams(page);
  const [date, setDate] = useDate(page);
  const [view, setView] = useView(page);
  const dataColumns = useColumns(page);
  const {
    showWeekend,
    showCoreHours,
    sortByProject,
  } = useSelector(
    (state: RootState) => state.viewSetting,
  );
  const showSidebar = useSelector(
    (state: RootState) => state.rightFilterState.showSidebar,
  );
  const tagColumnActive = useSelector(
    (state: RootState) => state.leftFilterState.worker.columns.includes('tag'),
  );
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [activeEventId, setActiveEventId] = useState<string | number | null>(
    null,
  );
  const [editMachineEvent, setEditMachineEvent] = useState<Assignment | null>(
    null,
  );
  const [editEquipmentEvent, setEditEquipmentEvent] = useState<EquipmentAssignment | null>(
    null,
  );
  const [copyMenu, setCopyMenu] = useState<{ x: number; y: number } | null>(
    null,
  );
  const [draggingEventId, setDraggingEventId] = useState<string | null>(null);
  const [machineEvent, setMachineEvent] = useState<TaskEvent | null>(null);
  const [equipmentEvent, setEquipmentEvent] = useState<TaskEvent | null>(null);
  const [moveOrCopyEvent, setMoveOrCopyEvent] = useState<MoveOrCopyEvent | null>(null);
  const [splitMode, setSplitMode] = useState<SplitMode>('none');
  const [splitCallback, setSplitCallback] = useState<((split: boolean) => void)|null>(null);
  const { data: activeTask = null, isFetching: activeTaskLoading } = useGetTaskQuery(activeEventId || skipToken);
  const timeframe = useTimeframe();

  const { data: nonWorkingDays } = useGetCalendarQuery({
    from: formatISO(startOfYear(date)),
    to: formatISO(endOfYear(date)),
  });
  const { data: allMachines } = useGetAllMachinesQuery();

  const calRef: RefObject<FullC> = useRef<FullC>(null);
  const calApi = useMemo(
    () => (calRef.current ? calRef.current.getApi() : null),
    [calRef.current],
  );

  useEffect(() => {
    if (calApi) calApi.changeView(view);
  }, [calApi, view]);

  useEffect(() => {
    if (calApi) calApi.gotoDate(date);
  }, [calApi, date]);

  useEffect(() => setFirstRun(false), []);

  useEffect(() => {
    if (calApi) setTimeout(() => calApi.trigger('_resize', true), 100);
  }, [left, tasks]);

  useEffect(() => {
    if (!calApi) return;
    calApi.refetchEvents();
    if (moveOrCopyEvent) {
      const task = tasks.find((a) => `${a.id}` === moveOrCopyEvent.event.id);
      if (!task) return;
      setShadow(calApi, task.start, task.end, task.parent);
    } else if (draggingEventId) {
      const task = tasks.find((a) => `${a.id}` === draggingEventId);
      if (!task) return;
      setShadow(calApi, task.start, task.end, task.parent);
    } else {
      clearShadow(calApi);
    }
  }, [calApi, tasks, moveOrCopyEvent, draggingEventId]);

  useDraggable();

  const holidays = useMemo(() => {
    if (!nonWorkingDays) return [];
    return nonWorkingDays.map(
      (n): EventInput => ({
        id: n.date,
        start: addHours(startOfDay(new Date(n.date)), 7),
        end: addHours(startOfDay(new Date(n.date)), 15),
        display: 'background',
        color: 'rgb(215, 215, 215)',
      }),
    );
  }, [nonWorkingDays]);

  const timelineShadowEvents = useMemo(() => {
    if (page !== 'worker') return [];
    return left.reduce((c, v): EventInput[] => {
      const common = {
        resourceId: `${v.id}`,
        display: 'background',
        color: 'rgb(215, 215, 215)',
      };
      const fromDate = v.startDate && subDays(new Date(v.startDate), 1);
      const endDate = v.endDate && addDays(new Date(v.endDate), 1);
      const hasStart = fromDate && isBefore(timeframe.from, fromDate);
      const hasEnd = endDate && isBefore(endDate, timeframe.to);
      const events = [...c];
      if (hasStart) {
        events.push({
          ...common,
          id: `bgStart${v.id}`,
          start: timeframe.from,
          end: fromDate,
        });
      }
      if (hasEnd) {
        events.push({
          ...common,
          id: `bgEnd${v.id}`,
          start: endDate,
          end: timeframe.to,
        });
      }
      return events;
    }, [] as EventInput[]);
  }, [left, timeframe]);

  const FCEvents = useMemo(() => {
    calApi?.removeAllEvents();
    return [
      ...tasks.map(
        (t): EventInput => ({
          ...t,
          id: `${t.id}`,
          title: t.label,
          start: t.start,
          end: t.end,
          resourceId: `${t.parent}`,
          borderColor: t.color,
          backgroundColor: t.ghost === true ? 'rgba(0,0,0,0)' : t.color,
          textColor:
            t.ghost === true
            || t.color.substring(0, 7) === '#ffde00'
            || t.color === '#fbc011'
              ? 'black'
              : 'white',
          classNames: t.color === '#fbc011' ? ['bold-text'] : [],
          groupId: t.reverseBackground === true ? 'reverse' : `${t.id}`,
          display:
            t.reverseBackground === true ? 'inverse-background' : undefined,
        }),
      ),
      ...timelineShadowEvents,
      ...holidays,
      shadowEvent,
    ];
  }, [tasks, holidays]);

  const sortFunc = useSorter();

  const FCResources = useMemo(() => {
    if (leftLoading) {
      return Array.from(Array(51).keys())
        .slice(1)
        .map((i) => ({
          id: `${i}`,
          title: `${i}`,
        }));
    }
    const sorted = left.sort(sortFunc);
    return sorted.map((l, i) => ({
      ...l,
      id: `${l.id}`,
      title: l.dummy === true ? l.label : `${l?.id} ${l.label}`,
      dummy: l.dummy === true,
      sortOrder:
          page === 'worker' ? leftPad(`${i}`, 10) : leftPad(`${l.id}`, 10),
    }));
  }, [leftLoading, left, tagColumnActive, sortFunc]);

  const FCColumns = useMemo(
    () => dataColumns.map(
      (c): ColSpec => ({
        field: c,
        headerContent: translateColumnName(c, true),
        width: c === 'id' || c === 'responsible_id' ? 70 : undefined,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellContent: (e) => {
          if (c === 'id') {
            if (Number.isNaN(parseInt(e.resource?.id || '0', 10))) return '';
            return true;
          }
          if (c === 'label' && page === 'worker' && e.resource?.id) {
            const resource = left.find(
              (w) => `${w.id}` === e.resource?.id,
            );
            const expCert = resource?.expiredCertifications;
            const missingSecTraining = resource?.missingSecurityTraining;
            return (
              <span className="line-wrapper">
                <span className="one-line-ellipse">
                  {e.resource?.title.replace(/^\d+ /, '')}
                </span>
                <span style={{ display: 'flex', gap: '10px', marginRight: '10px' }}>
                  <ExpiredCert expCert={expCert || 0} />
                  <MissingSecTraining expSecTraining={missingSecTraining || 0} />
                </span>
              </span>
            );
          }
          if (c === 'tag' && page === 'worker' && e.resource?.id) {
            if (Number.isNaN(parseInt(e.resource?.id || '0', 10))) return '';
            const worker = left.find((l) => `${l.id}` === `${e.resource?.id}`);
            return (
              <TagColumn key={`${e.resource?.id}`} worker={worker} />
            );
          }
          return true;
        },
      }),
    ),
    [dataColumns, left],
  );

  const closeCopyMenu = () => {
    setCopyMenu(null);
    setMoveOrCopyEvent(null);
  };

  const shouldShowSplitModal = (event: TaskEvent): SplitMode => {
    const resource: any = FCResources.find((x) => x.id === event.parent);
    if (resource?.dummy === true) return 'none'; // Don't split/adjust dummies
    const current = tasks.find((t) => t.id === event.id);

    const allOverlappingTasks = tasks
      .filter((t) => (
        (t.type === 'Normal' || t.type === 'Unavailability')
        && `${t.id}` !== `${event.id}`
        && `${t.parent}` === `${event.parent}`
      ))
      .filter((t) => areIntervalsOverlapping(
        { start: event.startTime, end: event.endTime },
        { start: t.start, end: t.end },
      ));

    const isBetween = (point: Date | number, interval: Interval) => isAfter(point, interval.start) && isBefore(point, interval.end);
    const overlappingTasks = allOverlappingTasks
      .filter((t) => t.parent !== current?.parent || !areIntervalsOverlapping(t, current))
      .filter((t) => isBetween(event.startTime, t) || isBetween(event.endTime, t));

    // If we are completely covering at least one other event, we dont allow splitting/Adjusting
    if (overlappingTasks.some((t) => !isBetween(event.startTime, t) && !isBetween(event.endTime, t))) {
      return 'none';
    }

    if (overlappingTasks.length === 1) {
      const task = overlappingTasks[0];
      if (isBefore(event.startTime, task.start)) {
        return 'right';
      } if (isAfter(event.endTime, task.end)) {
        return 'left';
      }

      return 'split';
    }
    if (overlappingTasks.length === 2) {
      const [t1, t2] = overlappingTasks;
      return areIntervalsOverlapping(t1, t2) ? 'none' : 'both';
    }

    return 'none';
  };

  const handleEvent = (e: TaskEvent, cb: (split: boolean | undefined) => void) => {
    const mode = shouldShowSplitModal(e);
    if (page === 'worker' && mode !== 'none') {
      setSplitMode(mode);
      setSplitCallback(() => cb);
    } else {
      cb(undefined);
    }
  };

  const eventReceived = ({ event, revert }: EventReceiveArg) => {
    revert();
    const e = eventRecieve2TaskEvent(event, view, showWeekend);
    if (!e) return;
    if (e.isMachine) {
      setMachineEvent(e);
    } else if (e.isEquipment) {
      setEquipmentEvent(e);
    } else {
      handleEvent(e, (split: boolean | undefined) => onEventAdded(e, view, event.remove, !!moveOrCopyEvent, split));
    }
  };

  const resetMoveOrCopyEvent = () => {
    const e = moveOrCopyEvent;
    if (!e) return;
    const originalEvent = e.oldEvent;
    if (originalEvent && originalEvent.start && originalEvent.end) {
      e.event.setStart(originalEvent.start);
      e.event.setEnd(originalEvent.end);
      e.event.setResources(originalEvent.getResources());
    }
    closeCopyMenu();
  };

  const eventChanged = (p?: MoveOrCopyEvent) => {
    const e = p || moveOrCopyEvent;
    if (!e) return;
    const originalEvent = e.oldEvent;
    const event = eventRecieve2TaskEvent(e.event);
    if (!event) return;
    if ('newResource' in e && e.newResource) {
      event.parent = parseInt(e.newResource.id, 10) || e.newResource.id;
    }
    const reset = () => {
      if (originalEvent && originalEvent.start && originalEvent.end) {
        e.event.setStart(originalEvent.start);
        e.event.setEnd(originalEvent.end);
        e.event.setResources(originalEvent.getResources());
      }
    };

    closeCopyMenu();
    handleEvent(event, (split: boolean | undefined) => onEventChanged(event, reset, split));
  };

  const eventAdded = (p?: { event: EventApi }) => {
    const e = p || moveOrCopyEvent;
    if (!e) return;
    const event = eventRecieve2TaskEvent(e.event);
    if (!event) return;
    event.id = tasks.find((t) => t.id === event?.id)?.origin || event.id;
    const reset = () => {
      e.event.remove();
    };

    closeCopyMenu();
    handleEvent(event, (split: boolean | undefined) => onEventAdded(event, view, reset, !!moveOrCopyEvent, split));
  };

  const eventDropped = (e: MoveOrCopyEvent) => {
    if (e.event.extendedProps.isMachine) {
      if ('newResource' in e) delete e.newResource;
    }
    if (page === 'project') return eventChanged(e);
    setCopyMenu({
      x: e.jsEvent.clientX - 20,
      y: e.jsEvent.clientY - 20,
    });
    setMoveOrCopyEvent(e);

    return null;
  };

  const menuItems = useMemo(() => {
    const res = [];
    res.push(
      <MenuItem key="move" onClick={() => eventChanged()}>
        Flytt oppdrag
      </MenuItem>,
    );
    res.push(
      <MenuItem key="copy" onClick={() => eventAdded()}>
        Kopiér oppdrag
      </MenuItem>,
    );
    res.push(
      <MenuItem key="cancel" onClick={resetMoveOrCopyEvent}>
        Avbryt
      </MenuItem>,
    );

    return res;
  }, [moveOrCopyEvent]);

  const leftHeader = useMemo(
    () => <LeftHeader filters={leftFilters} page={page} />,
    [page, leftFilters],
  );

  const eventContent = useCallback(
    (e: EventContentArg) => {
      const event = tasks.find((t) => `${t.id}` === e.event.id);
      let { title } = event?.isMachine || event?.isEquipment ? event : e.event;
      let isComment = !!event?.comment;
      const whiteArrow = event?.type === 'Normal' || event?.type === 'Loan';
      if (!event) {
        // Do nothing
      } else if (event.type === 'ExternalUnavailability') {
        title = event.sickPercentage
          ? `Fravær ${event.sickPercentage}%`
          : 'Fravær';
        isComment = false;
      } else if (event.type === 'Unavailability') {
        title = 'Avtalt Fravær';
      }
      if (event?.reverseBackground === true) {
        return (
          <div className="fc-event-main-frame">
            <span className="fc-event-title fc-sticky">{title}</span>
          </div>
        );
      }
      return (
        <Tooltip
          title={event ? <HoverEvent task={event} page={page} /> : ''}
          followCursor
          placement="right-start"
          enterDelay={1000}
          enterNextDelay={1000}
          arrow
        >
          <div className={`fc-event-main-frame ${whiteArrow ? 'white-arrow' : ''}`}>
            {isComment && <EventCommentIcon />}
            <span className="fc-event-title fc-sticky">{title}</span>
          </div>
        </Tooltip>
      );
    },
    [tasks],
  );

  useEffect(() => {
    if (!calApi) return;
    if (
      isWithinInterval(new Date(), {
        start: calApi.view.currentStart,
        end: calApi.view.currentEnd,
      })
    ) {
      let d = startOfDay(new Date());
      if (view === 'dag') d = addHours(d, 6);
      if (view === 'uke') d = subDays(d, 2);
      if (view === 'maned') d = startOfWeek(subDays(d, 2));
      if (view === 'kvartal') d = startOfMonth(subWeeks(d, 1));
      if (view === 'ar') d = startOfWeek(startOfMonth(subWeeks(d, 1)));

      setTimeout(
        () => calApi?.scrollToTime({
          minutes: differenceInMinutes(d, calApi?.view.currentStart),
        }),
        200,
      );
    } else {
      setTimeout(() => calApi?.scrollToTime(0), 200);
    }
  }, [view, date, calApi]);

  const eventClicked = useCallback(
    (e: EventClickArg) => {
      if (e.event.extendedProps.isMachine) {
        const machine = machines?.find((m) => m.id === e.event.id);
        setEditMachineEvent(machine || null);
        return;
      }
      if (e.event.extendedProps.isEquipment) {
        const item = equipment?.find((eq) => eq.id === e.event.id);
        setEditEquipmentEvent(item || null);
        return;
      }
      if (e.event.groupId !== 'reverse') {
        setActiveEventId(
          e.event.id.toString().length === 36 ? e.event.id : null,
        );
      }
    },
    [tasks, machines, equipment],
  );

  return (
    <Box
      className="full-calendar-wrapper"
      sx={{
        paddingRight: showSidebar ? '400px' : 0,
        transition: 'padding 0.2s easeOut',
      }}
    >
      <div
        className={`calendar-wrapper${
          taskLoading || fetching ? ' loading' : ''
        }`}
      >
        <TimelineHeader
          day={date}
          currentView={view}
          onDayChange={setDate}
          onCalendarViewChange={setView}
          filters={taskFilters?.value}
          filterActive={taskFilters?.active}
          showCheckboxOption={page === 'project'}
          showPrintButton={page === 'worker'}
        />
        <div
          className={`calendar-inner${
            view === 'uke' || view === 'kvartal' || view === 'ar' ? ' fc-hide-lines' : ''
          }`}
        >
          <FullC
            ref={calRef}
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            initialView="dag"
            initialDate={date}
            resources={FCResources}
            resourceAreaColumns={FCColumns}
            slotLaneClassNames={(a) => {
              if (!a.date) return '';
              const hour = getHours(a.date);
              if ([7, 15, 23].includes(hour)) return 'show-slot-divider';
              return '';
            }}
            resourceLabelDidMount={({ resource, el }) => {
              // eslint-disable-next-line no-param-reassign
              if (el?.parentElement) el.parentElement.onclick = () => onLeftSelect(parseInt(resource.id, 10), resource.title);
              if (el?.parentElement) {
                // eslint-disable-next-line no-param-reassign
                el.parentElement.className = `resource-style ${
                  page === 'worker' && resource.extendedProps.dummy
                    ? 'dummy'
                    : null
                }`;
              }
            }}
            resourceAreaHeaderContent={leftHeader}
            resourceOrder="dummy, sortOrder"
            eventOrderStrict={page === 'project' || sortByProject}
            eventOrder={
              sortByProject
                ? 'label,start,-duration'
                : 'sortOrder,start,-duration'
            }
            events={FCEvents}
            eventReceive={eventReceived}
            eventResize={eventChanged}
            eventDrop={eventDropped}
            eventContent={eventContent}
            eventClick={eventClicked}
            eventDragStart={(e) => setDraggingEventId(e.event.id)}
            eventDragStop={() => setDraggingEventId(null)}
            headerToolbar={false}
            nowIndicator
            droppable
            editable
            height="100%"
            eventResourceEditable
            resourceAreaWidth={300 + dataColumns.length * 50}
            locale={nbLocale}
            scrollTimeReset={false}
            displayEventTime={false}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5],
              startTime: '07:00',
              endTime: '15:00',
            }}
            visibleRange={
              firstRun
                ? undefined
                : {
                  start: timeframe.from,
                  end: timeframe.to,
                }
            }
            slotLabelClassNames="slot-label"
            views={{
              dag: {
                type: 'resourceTimeline',
                slotLabelFormat: { hour: '2-digit', minute: '2-digit' },
                snapDuration: '00:15',
                slotMinWidth: 50,
                slotLabelContent: (l) => l.text,
              },
              uke: {
                type: 'resourceTimeline',
                slotDuration: { hours: 1 },
                slotLabelFormat: [
                  { weekday: 'short', day: '2-digit', month: 'short' },
                  { hour: '2-digit', minute: '2-digit' },
                ],
                slotLabelContent: (l) => {
                  const hourLabel = showCoreHours ? '07:00 - 15:00' : l.text;
                  return (l.level !== 0
                    ? hourLabel
                    : l.text.slice(0, 2)
                    + l.text.slice(3, l.text.length).replace(/\.$/, ''));
                },
                slotLabelInterval: { hours: 8 },
                slotLabelClassNames: showCoreHours ? 'week-slot-hour-label' : '',
                snapDuration: { hours: 8 },
                slotMinWidth: 6,
                weekends: showWeekend,
                slotMinTime: { hours: showCoreHours ? 7 : -1 },
                slotMaxTime: { hours: showCoreHours ? 15 : 23 },
              },
              maned: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                snapDuration: { days: 1 },
                slotLabelFormat: [
                  { week: 'long' },
                  { weekday: 'short', day: '2-digit' },
                ],
                slotLabelContent: (l) => (l.level !== 1
                  ? l.text
                  : l.text.slice(0, 2)
                      + l.text.slice(3, l.text.length).replace(/\.$/, '')),
                slotMinWidth: 52.5,
                weekends: showWeekend,
              },
              kvartal: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                snapDuration: { days: 1 },
                slotLabelFormat: [{ month: 'long' }, { week: 'long' }],
                slotLabelInterval: { days: 7 },
                slotMinWidth: 10,
                weekends: showWeekend,
                slotLabelContent: (l) => l.text,
              },
              ar: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                slotLabelFormat: [{ month: 'long' }, { week: 'numeric' }],
                slotLabelInterval: { days: 7 },
                slotMinWidth: 5,
                weekends: showWeekend,
                slotLabelContent: (l) => l.text,
              },
            }}
            // Not a secret since it can easily be spotted in minified files
            // no matter where or how it is stored
            schedulerLicenseKey="0067403127-fcs-1701517614"
          />
        </div>
      </div>

      <Drawer
        variant="persistent"
        anchor="right"
        sx={{ zIndex: 0 }}
        PaperProps={{
          sx: { background: '#00000000', border: 'none' },
        }}
        open={showSidebar}
      >
        <Box paddingTop="64px">
          <Sidebar
            page={page}
            items={right}
            onSelect={onRightSelect}
            rightLoading={rightLoading}
            filters={rightFilters}
          />
        </Box>
      </Drawer>

      <TaskModal
        task={activeEventId && !activeTaskLoading ? activeTask : null}
        onRemove={onEventRemoved}
        onClose={() => setActiveEventId(null)}
      />

      {machineEvent !== null && (
        <Modal
          title={`Ny maskinbestilling - ${
            left.find((p) => p.id === machineEvent.parent)?.label
          }`}
          onClose={() => setMachineEvent(null)}
        >
          <MachineOrderModal
            event={machineEvent}
            isEdit
            machines={allMachines}
            projects={left}
            refetch={refetchMachines}
            onClose={() => setMachineEvent(null)}
          />
        </Modal>
      )}

      {editMachineEvent !== null && (
        <Modal
          title={`${
            editMachineEvent.type === 'Assignment'
              ? 'Maskintildeling'
              : 'Maskinbestilling'
          } - ${editMachineEvent.project.projectName}`}
          statusIcon={(
            <MachineOrderStatusIcon
              currStatus={editMachineEvent.status}
              type={editMachineEvent.type}
            />
          )}
          compactHeader
          onClose={() => setEditMachineEvent(null)}
        >
          <MachineOrderModal
            assignment={editMachineEvent}
            projects={left}
            machines={allMachines}
            isEdit
            refetch={refetchMachines}
            onClose={() => setEditMachineEvent(null)}
          />
        </Modal>
      )}

      {equipmentEvent !== null && (
      <Modal
        title={`Ny utstyrbestilling - ${
          left.find((p) => p.id === equipmentEvent.parent)?.label
        }`}
        onClose={() => setEquipmentEvent(null)}
      >
        <EquipmentOrderModal
          event={equipmentEvent}
          isEdit
          projects={left}
          refetch={refetchEquipment}
          onClose={() => setEquipmentEvent(null)}
        />
      </Modal>
      )}

      {editEquipmentEvent !== null && (
        <Modal
          title={`${
            editEquipmentEvent.type === 'Assignment'
              ? 'Utstyr tildeling'
              : 'Utstyr bestilling'
          } - ${editEquipmentEvent.project.projectName}`}
          statusIcon={(
            <MachineOrderStatusIcon
              currStatus={editEquipmentEvent.status}
              type={editEquipmentEvent.type}
            />
          )}
          compactHeader
          onClose={() => setEditEquipmentEvent(null)}
        >
          <EquipmentOrderModal
            assignment={editEquipmentEvent}
            projects={left}
            isEdit
            refetch={refetchEquipment}
            onClose={() => setEditEquipmentEvent(null)}
          />
        </Modal>
      )}

      {splitCallback !== null && page === 'worker' && (
      <SplitModal
        open={splitCallback !== null}
        mode={splitMode}
        onClose={() => {
          splitCallback(false);
          setSplitCallback(null);
        }}
        onSubmit={(v) => {
          splitCallback(v);
          setSplitCallback(null);
        }}
      />
      )}

      {(taskLoading || fetching || activeTaskLoading) && (
        <CircularProgress
          size={50}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
            zIndex: 1000,
          }}
        />
      )}
      <Menu
        open={copyMenu !== null}
        onClose={resetMoveOrCopyEvent}
        anchorReference="anchorPosition"
        anchorPosition={
          copyMenu !== null ? { top: copyMenu.y, left: copyMenu.x } : undefined
        }
      >
        {menuItems}
      </Menu>
    </Box>
  );
};
