import { useEffect, useMemo } from 'react';
import { format, getWeek } from 'date-fns';
import { Event } from '../../core/types/util/event';
import { Page } from '../../shared/components/page';
import { useSelector } from '../../core/hooks/redux';
import { useOrganizations } from '../../core/redux/leftFilterState';
import {
  useGetAllWorkersQuery,
  useGetAllProjectsQuery,
} from '../../core/redux/worker';
import {
  eventBackgroundColor,
  useIsLoaned,
} from '../../core/helpers/taskHelpers';
import { useTimeFrameStrings } from '../../core/hooks/useTimeframe';
import { useDisplayableDummies } from '../../core/hooks/useDummies';
import { useTasks } from '../../core/hooks/useTasks';
import { PrintablePlanner } from '../../components/printablePlanner';
import { useDate } from '../../core/hooks/useDate';
import './style.scss';

export const WorkerPlannerPrintable = () => {
  const organizations = useOrganizations();
  const timeframe = useTimeFrameStrings();
  const isLoaned = useIsLoaned();
  const dummies = useDisplayableDummies();

  const leftLeaderFilter = useSelector((l) => l.leftFilterState.worker.leaders) || [];
  const leftEmploymentTypeFilter = useSelector((l) => l.leftFilterState.worker.employmentTypes) || [];
  const leftJobRoleFilter = useSelector((l) => l.leftFilterState.worker.jobRoles) || [];
  const taskProjectFilter = useSelector((s) => s.taskFilterState.worker.projects) || [];

  const { data: workers, isLoading: leftLoading } = useGetAllWorkersQuery({ organizations, ...timeframe });

  const { data: orgProjects } = useGetAllProjectsQuery({ organizations, includeInternalProduction: true });

  const { tasks } = useTasks();

  const workersWithTasks = useMemo(() => {
    const s = new Set(tasks.map((t) => (
      t.worker?.employeeNumber.toString() || t.workerType || ''
    )));
    s.delete('');
    return s;
  }, [tasks]);

  const plannerWorkers = useMemo(() => {
    const work = workers
      ?.filter((w) => leftLeaderFilter.length === 0 || leftLeaderFilter.some((l) => l.id === w.leaderId || l.id === w.employeeNumber))
      .filter((w) => leftEmploymentTypeFilter.length === 0 || leftEmploymentTypeFilter.some((t) => t.label === w.employmentType))
      .filter((w) => leftJobRoleFilter.length === 0 || leftJobRoleFilter.some((j) => j.id === w.jobRole))
      ?.map((e) => ({
        ...e,
        id: e.employeeNumber,
        label: e.fullName,
        expiredCertifications: e.expiredCertifications,
        missingSecurityTraining: e.missingSecurityTraining,
      })) || [];
    const dummy = dummies.map((d) => ({ ...d, id: d.label })).filter((d) => workersWithTasks.has(`${d.id}`));

    return [...work, ...dummy];
  }, [workers, leftLeaderFilter, leftEmploymentTypeFilter, leftJobRoleFilter, workersWithTasks]);

  const plannerTasks: Event[] = useMemo(() => (
    tasks?.map((t) => {
      let label = `${t.project?.id} - ${t.project?.projectName}`;
      if (isLoaned(t)) {
        label = '';
      } else if (t.type === 'Loan') {
        label = (`${t.status === 'Approved' ? 'Utlån' : 'Utlånsforespørsel'} til ${t.loanerOrganization?.name}`);
      }
      return {
        id: t.id,
        label,
        start: new Date(t.startTime),
        end: new Date(t.endTime),
        parent: t.worker?.employeeNumber.toString() || t.workerType || '0',
        origin: t.project?.id?.toString() ?? '0',
        color: eventBackgroundColor(t.worker?.employeeNumber, t.type, t.status, t.sickPercentage),
        durationEditable: false,
        editable: false,
        resourceEditable: false,
        ghost: taskProjectFilter.length > 0 && !taskProjectFilter.some((p) => p.id === t.project?.id),
        reverseBackground: isLoaned(t),
        type: t.type,
        title: t.title,
        comment: t.comment,
        sickPercentage: t.sickPercentage,
      };
    }) || []
  ), [tasks, taskProjectFilter]);

  const taskFilter = (t: Event) => {
    if (t.type !== 'Normal') return true;
    const findOrg = orgProjects?.find((p) => `${p.id}` === t.origin);
    const internalOrgs = findOrg?.internalProduction.map((i) => i.id) || [];
    return organizations.length === 0
      || (organizations.includes(findOrg?.responsible?.parentOrganizationId || 1)
      || organizations.includes(findOrg?.coResponsible?.parentOrganizationId || 1)
      || organizations.some((o) => internalOrgs.includes(o)));
  };

  const [date] = useDate('worker');
  useEffect(() => {
    document.title = `Ukeplan uke ${getWeek(date)} ${format(date, 'yyyy')}`;
  }, [date]);

  return (
    <Page className="worker-planner-printable-page">
      <PrintablePlanner
        page="worker"
        left={plannerWorkers}
        tasks={plannerTasks.filter(taskFilter)}
        leftLoading={leftLoading}
      />
    </Page>
  );
};
