/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ProjectSortTypes } from '../types/enums/projectSortTypes';
import { SelectItem } from '../../shared/types/util/selectItem';

const stateName = 'rightFilterState';

interface InitialState {
  project: {
    leaders: SelectItem[],
  },
  worker: {
    projects: SelectItem[],
    org: SelectItem[],
    search: string,
    sort: {value: ProjectSortTypes, desc: boolean}
    includeInternalProduction: boolean,
    includeOwnProjects: boolean,
  },
  defaultSet: boolean,
  showSidebar: boolean,
}

const initialState: InitialState = {
  project: {
    leaders: [],
  },
  worker: {
    projects: [],
    org: [],
    search: '',
    sort: { value: 'id', desc: false },
    includeInternalProduction: false,
    includeOwnProjects: true,
  },
  defaultSet: false,
  showSidebar: true,
};

const rightFilterState = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setIncludeInternalProduction(state, { payload: includeInternalProduction }: PayloadAction<boolean>) {
      state.worker.includeInternalProduction = includeInternalProduction;
    },
    setIncludeOwnProjects(state, { payload: includeOwnProjects }: PayloadAction<boolean>) {
      state.worker.includeOwnProjects = includeOwnProjects;
    },
    setDefaultOrg(state, { payload: org }: PayloadAction<SelectItem>) {
      if (!state.defaultSet) {
        state.defaultSet = true;
        if (!state.worker.org) {
          state.worker.org = [];
        }
        if (!state.worker.org.some((o) => o.id === org.id)) {
          state.worker.org = [...state.worker.org, org];
        }
      }
    },
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
    setProjectOrg(state, { payload: org }: PayloadAction<SelectItem[]>) {
      state.worker.org = org;
    },
    setProjectSort(state, { payload: sort }: PayloadAction<{value: ProjectSortTypes, desc: boolean}>) {
      state.worker.sort = sort;
    },
    setProjectSearch(state, { payload: search }: PayloadAction<string>) {
      state.worker.search = search;
    },
    setProjectLeaders(state, { payload: leaders }: PayloadAction<SelectItem[]>) {
      state.project.leaders = leaders;
    },
    setWorkerProjects(state, { payload: projects }: PayloadAction<SelectItem[]>) {
      state.worker.projects = projects;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setIncludeInternalProduction,
  setIncludeOwnProjects,
  toggleSidebar,
  setDefaultOrg,
  setProjectOrg,
  setProjectSort,
  setProjectSearch,
  setProjectLeaders,
  setWorkerProjects,
  reset,
} = rightFilterState.actions;

export default persistReducer({ key: stateName, storage }, rightFilterState.reducer);
