import { useMemo } from 'react';
import { Displayable } from '../../shared/types/util/displayable';
import { useGetDummiesQuery } from '../redux/worker';
import { SelectItem } from '../../shared/types/util/selectItem';

export const useDummies = () => {
  const { data = [] } = useGetDummiesQuery();

  const dummies = useMemo((): SelectItem[] => [...data].sort((a, b) => a.sortOrder - b.sortOrder).map((d, i) => ({
    id: -i - 1,
    label: d.name,
  })), [data]);

  return dummies;
};

export const useDisplayableDummies = () => {
  const { data = [] } = useGetDummiesQuery();

  const dummies = useMemo((): Displayable[] => [...data].sort((a, b) => a.sortOrder - b.sortOrder).map((d, i) => ({
    id: -i - 1,
    label: d.name,
    dummy: true,
  })), [data]);

  return dummies;
};
