import React, { FC, useMemo, useState } from 'react';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useGetAllProjectsQuery } from '../../core/redux/worker';
import { Project } from '../../core/types/project';
import './style.scss';
import { SelectItem } from '../../shared/types/util/selectItem';

export const ProjectsPicker: FC<{
  value?: SelectItem[],
  onChange?: (newValue: SelectItem[]) => void
  label?: string,
  size?: 'medium' | 'small',
  disabled?: boolean,
  required?: boolean,
  projects?: Project[],
  }> = ({
    value = [],
    disabled = false,
    onChange = () => null,
    label = 'Søk etter prosjekt',
    size = 'medium',
    required = false,
    projects,
  }) => {
    const [isPickerOpen, setPickerOpen] = useState<boolean>(false);
    const {
      data = [],
      isLoading,
    } = useGetAllProjectsQuery(undefined, { skip: projects !== undefined });

    const rawProjects = useMemo(() => {
      if (projects !== undefined) {
        return projects;
      }
      return data;
    }, [data, projects]);

    const options = useMemo((): SelectItem[] => (
      [...rawProjects]
        .sort((a, b) => (
          (a.id === 0 ? Infinity : a.id) - (b.id === 0 ? Infinity : b.id)
        ))
        .map((p): SelectItem => ({
          id: p.id,
          label: `${p.id} - ${p.projectName}`,
        }))
    ), [rawProjects]);

    if (isLoading) {
      return (
        <Skeleton
          width="100%"
          height={`${size === 'medium' ? 56 : 40}px`}
          variant="rectangular"
        />
      );
    }

    return (
      <Autocomplete
        className="projects-picker-component"
        multiple
        disableCloseOnSelect
        limitTags={1}
        value={value}
        disabled={disabled}
        isOptionEqualToValue={(a, b) => `${a.id}` === `${b.id}`}
        clearOnBlur
        clearOnEscape
        openOnFocus
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        options={options}
        fullWidth
        getOptionLabel={(option) => option?.label}
        onOpen={() => setPickerOpen(true)}
        onClose={() => setPickerOpen(false)}
        renderTags={(items) => (
          !isPickerOpen && (
            <span className="filter-display-line">
              <span className="one-line-ellipse flexchild">
                {`${items[0].label}`}
              </span>
              <span>
                {`${items.length > 1 ? (` + ${items.length - 1}`) : ''}`}
              </span>
            </span>
          )
        )}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            // className="avoid-height-shift"
            label={label}
            helperText={required && value.length <= 0 ? 'Velg et prosjekt' : null}
            error={required && value.length <= 0}
          />
        )}
        size={size}
      />
    );
  };
