import React, { FC } from 'react';
import { Skeleton } from '@mui/material';

export const LoadingSidebarItem: FC = () => (
  <Skeleton
    sx={{
      bgcolor: '#ffffff', ml: 1, borderRadius: 1,
    }}
    width="95%"
    height={20}
    variant="rectangular"

  />
);
