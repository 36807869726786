import { FC, ReactNode } from 'react';
import { PageName } from '../../../core/types/util/pageName';
import { Displayable } from '../../../shared/types/util/displayable';
import { SidebarList } from './sidebarList';

export const Sidebar: FC<{
  page: PageName
  items: Displayable[],
  onSelect: (id: number | string, title: string) => void,
  rightLoading?: boolean,
  filters?: ReactNode,
}> = ({
  items,
  page,
  onSelect = () => null,
  rightLoading = false,
  filters,
}) => (
  <div>
    <SidebarList page={page} items={items} onSelect={(e) => onSelect(e.id, e.label)} rightLoading={rightLoading} filters={filters} />
  </div>
);
