import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { loggerMiddleware } from './loggerMiddleware';
import worker, { workerApi } from './worker';
import machine, { machineApi } from '../../shared/redux/machine';
import rental, { rentalApi } from '../../shared/redux/rental';
import version, { versionApi } from '../../shared/redux/version';
import rentalOrderState from '../../shared/redux/local/rentalOrderState';
import leftFilterState from './leftFilterState';
import taskFilterState from './taskFilterState';
import rightFilterState from './rightFilterState';
import viewSetting from './viewSetting';

// Put additional reducers here
export const store = configureStore({
  reducer: {
    rentalOrderState,
    leftFilterState,
    taskFilterState,
    rightFilterState,
    viewSetting,
    worker,
    machine,
    rental,
    version,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat(workerApi.middleware, machineApi.middleware, rentalApi.middleware, versionApi.middleware)
    .concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
