import React, { FC } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { CircularProgress, IconButton } from '@mui/material';

export const Favourite: FC<{
  enabled?: boolean,
  onClick?: () => void,
  icon?: boolean,
  loading?: boolean
}> = (
  {
    enabled = false,
    onClick = () => null,
    icon = false,
    loading = false,
  },
) => {
  if (icon) {
    return (
      <StarIcon
        sx={{
          color: '#ffd700',
          m: 0,
          p: 0,
          fontSize: '14px',
          transform: 'scale(1.5) translateY(1px)',
          marginLeft: '5px',
        }}
      />
    );
  }
  if (loading) {
    return (
      <IconButton sx={{ m: 0, p: 0.2, pl: 0.6 }}>
        <CircularProgress sx={{ color: '#ffd700' }} disableShrink thickness={8} size={18} />
      </IconButton>
    );
  }
  return (
    <IconButton
      onClick={onClick}
      sx={{ m: 0, p: 0.2 }}
    >
      {
            enabled
              ? <StarIcon sx={{ color: '#ffd700' }} />
              : <StarOutlineIcon sx={{ color: '#ffd700' }} />
          }
    </IconButton>
  );
};
