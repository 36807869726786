import { Assignment } from '../../shared/types/assignment';
import { EquipmentAssignment } from '../../shared/types/equipmentAssignment';
import {
  EquipmentEditRules,
  equipmentEditRulesDefaults,
} from '../../shared/types/equipmentEditRules';
import {
  MachineEditRules,
  machineEditRulesDefaults,
} from '../../shared/types/machineEditRules';
import { SelectItem } from '../../shared/types/util/selectItem';

/**
 * Converts nested objects into attributes
 *
 * @param attributes Attributes to process
 * @param prefix Prefix for attributes
 * @returns Object with attribute names and values
 */
export const getAttributeSet = (
  attributes: any,
  prefix = 'data-',
): Record<string, string> => {
  const keys = Object.keys(attributes);
  let attribs: Record<string, string> = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = attributes[key];
    if (value === null) {
      attribs[(prefix + key).toLowerCase()] = '';
    } else if (
      typeof value === 'string'
      || typeof value === 'number'
      || typeof value === 'boolean'
    ) {
      attribs[(prefix + key).toLowerCase()] = `${value}`;
    } else if (typeof value === 'object') {
      attribs = { ...attribs, ...getAttributeSet(value, `${prefix}${key}-`) };
    }
  }
  return attribs;
};

/**
 * Gets a list of attributes from a given element
 *
 * @param el Element to get from
 * @param attributes List of attributes to get
 * @param prefix Prefix used to get attributes - does not appear in data
 * @returns Object of attribute name -> attribute value
 */
export const getAttributes = (
  el: { getAttribute: (arg0: string) => any },
  attributes: string[],
  prefix = 'data-',
) => {
  const obj: Record<string, string | null> = {};
  for (let i = 0; i < attributes.length; i += 1) {
    const att = attributes[i];
    obj[att] = el.getAttribute((prefix + att).toLowerCase());
  }
  return obj;
};

export const labelSort = (a: SelectItem, b: SelectItem) => a.label.localeCompare(b.label, 'nb');

export const boolSort = <T>(getter: (item: T) => boolean, dir: -1 | 1) => (a: T, b: T) => dir * ((getter(a) ? 1 : 0) - (getter(b) ? 1 : 0));

/**
 * BooleanSort - Sorting function for boolean values
 *
 * @param a First item to compare
 * @param b Second item to compare
 * @param dir Direction to sort in
 * @returns Number between -1 and 1, depending on the direction
 */
export const bs = (
  a: undefined | boolean,
  b: undefined | boolean,
  dir: -1 | 1 = -1,
) => dir * ((a === true ? 1 : 0) - (b === true ? 1 : 0));

export const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};

export const logHeaders = (headers: Headers) => {
  const head = headers.keys();
  let val = head.next();
  // eslint-disable-next-line no-console
  console.group('Headers');
  while (!val.done) {
    // eslint-disable-next-line no-console
    console.log(`${val.value}:`, headers.get(val.value));
    val = head.next();
  }
  // eslint-disable-next-line no-console
  console.groupEnd();
};

export const editMachineRules = (
  newOrder?: boolean,
  event?: Assignment,
  canEdit?: boolean,
): MachineEditRules => {
  if (newOrder) {
    return {
      ...machineEditRulesDefaults,
      editTime: true,
      editProject: true,
      editComment: true,
      editSubCat: true,

      seeMainCat: true,
      seeSubCat: true,
      seeComment: true,

      seeSaveButton: true,

      closeButtonText: 'Avbryt',
    };
  }

  if (event) {
    if (event.type === 'Order') {
      return {
        ...machineEditRulesDefaults,
        editTime: canEdit ?? false,
        editComment: canEdit ?? false,
        editSubCat: canEdit ?? false,

        seeMainCat: true,
        seeSubCat: true,
        seeComment: true,
        seeLastEditBy: true,

        seeCancelButton: canEdit ?? false,
        seeSaveButton: canEdit ?? false,

        closeButtonText: canEdit ? 'Avbryt' : 'Lukk',
      };
    }

    if (event.type === 'Assignment' && event.status === 'Created') {
      return {
        ...machineEditRulesDefaults,
        seeMachine: true,
        seeLastEditBy: true,
        editTime: canEdit ?? false,

        seeSaveButton: canEdit ?? false,
        seeCancelButton: canEdit ?? false,
      };
    }

    if (event.type === 'Assignment' && event.status === 'Approved') {
      return {
        ...machineEditRulesDefaults,
        editTime: canEdit ?? false,

        seeMachine: true,
        seeLastEditBy: true,
        seeApprovedBy: true,

        seeSaveButton: canEdit ?? false,

        closeButtonText: canEdit ? 'Avbryt' : 'Lukk',
      };
    }
  }

  return {
    ...machineEditRulesDefaults,
  };
};

export const editEquipmentRules = (
  newOrder?: boolean,
  event?: EquipmentAssignment,
  canEdit?: boolean,
): EquipmentEditRules => {
  if (newOrder) {
    return {
      ...equipmentEditRulesDefaults,
      editTime: true,
      editProject: true,
      editComment: true,
      editSubCat: true,

      seeMainCat: true,
      seeSubCat: true,
      seeComment: true,

      seeSaveButton: true,

      closeButtonText: 'Avbryt',
    };
  }

  if (event) {
    if (event.type === 'Order') {
      return {
        ...equipmentEditRulesDefaults,
        editTime: canEdit ?? false,
        editComment: canEdit ?? false,
        editSubCat: canEdit ?? false,

        seeMainCat: true,
        seeSubCat: true,
        seeComment: true,
        seeLastEditBy: true,

        seeCancelButton: canEdit ?? false,
        seeSaveButton: canEdit ?? false,

        closeButtonText: canEdit ? 'Avbryt' : 'Lukk',
      };
    }

    if (event.type === 'Assignment' && event.status === 'Created') {
      return {
        ...equipmentEditRulesDefaults,
        seeEquipment: true,
        seeLastEditBy: true,
        editTime: canEdit ?? false,

        seeSaveButton: canEdit ?? false,
        seeCancelButton: canEdit ?? false,
      };
    }

    if (event.type === 'Assignment' && event.status === 'Approved') {
      return {
        ...equipmentEditRulesDefaults,
        editTime: canEdit ?? false,

        seeEquipment: true,
        seeLastEditBy: true,
        seeApprovedBy: true,

        seeSaveButton: canEdit ?? false,

        closeButtonText: canEdit ? 'Avbryt' : 'Lukk',
      };
    }
  }

  return {
    ...equipmentEditRulesDefaults,
  };
};
