import {
  Box,
  TextField,
  Typography,
} from '@mui/material';
import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  addHours,
  areIntervalsOverlapping,
  formatISO,
  isAfter,
  isBefore,
  roundToNearestMinutes,
  startOfDay,
  Interval,
} from 'date-fns';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { Task } from '../../core/types/task';
import { PopConfirm } from '../../shared/components/popConfirm';
import { UpdateAbsence } from '../../core/api/updateAbsence';
import {
  useDeleteAbsenceMutation,
  useGetAllTasksQuery,
  usePostAbsenceMutation,
  usePutAbsenceMutation,
} from '../../core/redux/worker';

import { NOT_DELIVERED_COLOR } from '../../shared/constants';
import { ensureAfter } from '../../shared/logic/dates';
import { Button } from '../../shared/components/button';
import { DateTime } from '../../shared/types/util/dateTime';
import { SplitModal, SplitMode } from '../planner/partials/splitModal';

const stableArray: never[] = [];

export const EditAbsence: FC<{
  task?: Task|undefined,
  userId?: string|number,
  onClose?: () => void,
  startDate?: Date,
}> = ({
  task,
  userId,
  onClose = () => null,
  startDate = new Date(),
}) => {
  const [from, setFrom] = useState<Date>(addHours(startOfDay(startDate), 7));
  const [to, setTo] = useState<Date>(addHours(startOfDay(startDate), 15));
  const [comment, setComment] = useState<string>('');

  const [splitMode, setSplitMode] = useState<SplitMode>('none');
  const [splitCallback, setSplitCallback] = useState<((split: boolean) => void)|null>(null);

  const { data: tasks = stableArray } = useGetAllTasksQuery({
    from: formatISO(from),
    to: formatISO(to),
    // Skip if editing
  }, { skip: task !== undefined });

  const [sendCreateRequest, { isLoading: isCreating }] = usePostAbsenceMutation();
  const [sendUpdate, { isLoading: isUpdating }] = usePutAbsenceMutation();
  const [sendDelete, { isLoading: isDeleting }] = useDeleteAbsenceMutation();

  const loading = useMemo(
    () => isDeleting || isUpdating || isCreating,
    [isDeleting, isUpdating, isCreating],
  );

  useEffect(() => {
    if (!task) return;
    setFrom(roundToNearestMinutes(new Date(task.startTime), { nearestTo: 15 }));
    setTo(roundToNearestMinutes(new Date(task.endTime), { nearestTo: 15 }));
    setComment(task.comment || '');
  }, [task]);

  const changeFrom = (date: Date) => {
    setFrom(date);
    setTo((old) => ensureAfter(old, date));
  };

  const convertToInterval = (data: {
    startTime: DateTime,
    endTime: DateTime
  }): Interval => ({
    start: new Date(data.startTime),
    end: new Date(data.endTime),
  });

  // TODO: Could probably be combined with the other function of the same name
  const shouldShowSplitModal = (): SplitMode => {
    const overlappingTasks = tasks
      .filter((t) => `${t.worker?.employeeNumber}` === `${userId}`)
      .filter((t) => !task || `${t.id}` !== `${task.id}`);

    const isBetween = (
      point: Date | number,
      interval: {startTime: DateTime, endTime: DateTime},
    ) => (
      isAfter(point, new Date(interval.startTime))
      && isBefore(point, new Date(interval.endTime))
    );

    // If we are completely covering at least one other event, we dont allow splitting/Adjusting
    if (overlappingTasks.some((t) => !isBetween(from, t) && !isBetween(to, t))) {
      return 'none';
    }

    if (overlappingTasks.length === 1) {
      const item = overlappingTasks[0];
      if (isBefore(from, new Date(item.startTime))) {
        return 'right';
      } if (isAfter(to, new Date(item.endTime))) {
        return 'left';
      }

      return 'split';
    }
    if (overlappingTasks.length === 2) {
      const [t1, t2] = overlappingTasks;
      return areIntervalsOverlapping(convertToInterval(t1), convertToInterval(t2)) ? 'none' : 'both';
    }

    return 'none';
  };

  const deleteAbsence = () => {
    if (!task) return;
    if (!task.worker) return;
    sendDelete({ workerId: task.worker?.employeeNumber, absenceId: task.id }).unwrap().then(() => {
      onClose();
    });
  };

  const sendCreate = (split: boolean = false) => {
    if (task !== undefined || userId === undefined) return;
    const body: UpdateAbsence = {
      startTime: formatISO(from),
      endTime: formatISO(to),
      comment,
      split,
    };
    sendCreateRequest({ workerId: userId, body }).unwrap().then(() => {
      onClose();
    });
  };

  const createAbsence = () => {
    const split = shouldShowSplitModal();
    if (split !== 'none') {
      setSplitMode(split);
      setSplitCallback(() => (s: boolean) => sendCreate(s));
    } else {
      sendCreate();
    }
  };

  const updateAbsence = () => {
    if (!task) return;
    if (!task.worker) return;
    const body: UpdateAbsence = {
      startTime: formatISO(from),
      endTime: formatISO(to),
      comment,
    };
    sendUpdate({ workerId: task.worker?.employeeNumber, absenceId: task.id, body }).unwrap().then(() => {
      onClose();
    });
  };

  const isValid = useMemo(() => {
    if (isBefore(from, to)) return true;
    return false;
  }, [from, to]);

  if (task && task.type !== 'Unavailability') return <span>Dette er ikke et fravær</span>;
  return (
    <div className="edit-absence-component">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker size="medium" fullWidth label="Fra" value={from} onChange={changeFrom} defaultTime={7} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker size="medium" fullWidth label="Til" value={to} onChange={setTo} minDate={from} defaultTime={15} />
          </Box>
        </Box>
        <Box>
          <TextField
            label="Kommentar"
            fullWidth
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            minRows={4}
          />
        </Box>
        {!isValid && (
          <Typography color={NOT_DELIVERED_COLOR} align="right">Fra tid må være før til tid</Typography>
        )}
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            {task !== undefined && (
              <PopConfirm
                buttonProps={{ disabled: !isDeleting && loading }}
                loading={isDeleting}
                content={(
                  <>
                    Vil du slette dette fraværet?<br />
                    Det vil ikke være mulig å angre slettingen
                  </>
                )}
                onConfirm={deleteAbsence}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button color="primary" variant="outlined" disabled={loading} onClick={onClose}>Avbryt</Button>
            <Button
              disabled={!isValid || (!(isCreating || isUpdating) && loading)}
              loading={isCreating || isUpdating}
              color="primary"
              variant="contained"
              onClick={task === undefined ? createAbsence : updateAbsence}
            >Lagre
            </Button>
          </Box>
        </Box>
      </Box>
      {splitCallback !== null && (
        <SplitModal
          open={splitCallback !== null}
          mode={splitMode}
          onClose={() => {
            splitCallback(false);
            setSplitCallback(null);
          }}
          onSubmit={(v) => {
            splitCallback(v);
            setSplitCallback(null);
          }}
        />
      )}
    </div>
  );
};
