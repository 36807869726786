import { FC, useState } from 'react';
import {
  Box,
  Tab,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  useGetAllCertificationsWorkerQuery,
  useGetAllDocumentedSecurityTrainingQuery,
  useGetWorkerQuery,
} from '../../core/redux/worker';
import { Details } from './partials/details';
import { Certifications } from '../../shared/components/certification/certifications';
import { Loans } from './partials/loans';
import './style.scss';
import { SecurityTrainings } from '../../shared/components/securityTraining/securityTraining';

export const WorkerDetails: FC<{
  id: number,
}> = ({
  id,
}) => {
  // oversikt
  const { data: worker, isLoading: workerLoading } = useGetWorkerQuery(id);

  // kompetanse/kurs
  const { data: certifications, isLoading: certLoading } = useGetAllCertificationsWorkerQuery(id);
  const { data: securityTrainings, isLoading: secsLoading } = useGetAllDocumentedSecurityTrainingQuery(id);

  const [tab, setTab] = useState<string>('1');

  const cardNumber = certifications?.find((c) => c.name === 'HMS kort')?.cardNumber;

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)} aria-label="ansatt tabs">
            <Tab label="Oversikt" value="1" />
            <Tab label="Utlån" value="2" />
            <Tab label="Kompetanse/Kurs" value="3" />
            <Tab label="Dok. Sikkerhetsopplæring" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"><Details worker={worker} cardNumber={cardNumber} isLoading={workerLoading} /></TabPanel>
        <TabPanel value="2"><Loans worker={worker} /></TabPanel>
        <TabPanel value="3"><Certifications cert={certifications} isLoading={certLoading} /></TabPanel>
        <TabPanel value="4"><SecurityTrainings secs={securityTrainings} isLoading={secsLoading} /></TabPanel>
      </TabContext>
    </Box>
  );
};
