import { useMemo } from 'react';
import {
  Box, Button,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PopMenu } from '../../../shared/components/popMenu';
import { TAG_COLORS } from '../../../shared/constants';

export const ColorPicker = ({
  color,
  isLoading = false,
  onChange = () => null,
} : {
    color: string,
    isLoading?: boolean,
    onChange: (bg: string, fg: string) => void,
}) => {
  const btnStyle = {
    maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px',
  };

  const btnContent = useMemo(() => (
    <Box display="flex" flex={1} sx={{ cursor: 'pointer' }}>
      <Box flex={1} bgcolor={color} />
      <Box alignSelf="center"><ArrowDropDownIcon /></Box>
    </Box>
  ), [color]);

  if (isLoading) return btnContent;

  return (
    <PopMenu
      button={btnContent}
      width="300px"
      side="right"
    >
      <Box display="flex" flexDirection="row" flexWrap="wrap" bgcolor="#d9d9d9" alignItems="space-evenly" padding={1} gap={1}>
        {TAG_COLORS.map((c) => (
          <Button
            onClick={() => onChange(c.background, c.foreground)}
            sx={{
              borderColor: '#d9d9d9',
              borderRadius: '8px',
              bgcolor: c.background,
              transition: 'border 0.1s',
              borderWidth: '3px',
              borderStyle: 'solid',
              '&.MuiButtonBase-root:hover': {
                bgcolor: c.background,
                borderRadius: '5px',
                borderWidth: '0px',
              },
              ...btnStyle,
            }}
          />
        ))}
      </Box>
    </PopMenu>
  );
};
