import {
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import { useGetAllTagsQuery, usePutWorkerTagMutation } from '../../core/redux/worker';
import { Modal } from '../../shared/components/modal';
import { PopMenu } from '../../shared/components/popMenu';
import { Displayable } from '../../shared/types/util/displayable';
import { TagModalContent } from './partials/tagModalContent';
import { TagButton } from './partials/tagButton';

export const TagColumn = ({
  worker,
}: {
  worker?: Displayable,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: tags, isLoading: tagsLoading, isFetching: tagsFetching } = useGetAllTagsQuery();
  const [putWorkerTag] = usePutWorkerTagMutation();

  return (
    <>
      <PopMenu
        stopPropagation
        width="200px"
        button={<TagButton worker={worker} />}
      >
        <Box
          flexDirection="column"
          maxHeight={300}
          overflow="auto"
        >
          {tagsLoading ? (
            <CircularProgress size={24} />
          ) : (
            tags?.map((t) => (
              <Box
                key={t.id}
                onClick={() => putWorkerTag({ workerId: parseInt(`${worker?.id}`, 10), tagId: t.id })}
                sx={{
                  padding: 0.75,
                  paddingLeft: 1.25,
                  cursor: 'pointer',
                  borderRadius: '3px',
                  fontSize: 16,
                  backgroundColor: t.background,
                  color: t.foreground,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginBottom: 1,
                }}
              >
                {t.label}
              </Box>
            ))
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1} marginTop={2}>
          <Button fullWidth variant="contained" onClick={() => setIsOpen(true)}>Rediger merker...</Button>
          {worker?.tag && (
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={() => putWorkerTag({ workerId: parseInt(`${worker?.id}`, 10), tagId: 0 })}
            >
              Fjern merknad
            </Button>
          )}
        </Box>
      </PopMenu>
      {isOpen && (
      <Modal
        title="Merknad"
        compactHeader
        onClose={() => setIsOpen(false)}
      >
        <TagModalContent tags={tags} tagsLoading={tagsLoading || tagsFetching} />
      </Modal>
      )}
    </>
  );
};
