import {
  FC,
  ReactNode,
} from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { PageName } from '../../../core/types/util/pageName';
import { PopMenu } from '../../../shared/components/popMenu';
import { LoadingSidebarItem } from './loadingSidebarItem';
import { SidebarItem } from './sidebarItem';
import { useDispatch, useSelector } from '../../../core/hooks/redux';
import {
  setProjectSearch,
  setIncludeInternalProduction,
  setIncludeOwnProjects,
} from '../../../core/redux/rightFilterState';
import { Displayable } from '../../../shared/types/util/displayable';
import { bs } from '../../../core/helpers/functions';
import { useDummies } from '../../../core/hooks/useDummies';
import { useOrganizations } from '../../../core/redux/leftFilterState';
import { useGetCategoriesQuery } from '../../../shared/redux/machine';
import { useGetCategoriesQuery as getEquipmentCategories } from '../../../shared/redux/rental';
import { RootState } from '../../../core/redux';
import { FilterIcon } from '../../../shared/components/filterIcon';

export const SidebarList: FC<{
  page: PageName,
  items: Displayable[],
  onSelect: (item: Displayable) => void,
  rightLoading?: boolean,
  filters?: ReactNode,
}> = ({
  page,
  items,
  onSelect,
  rightLoading = false,
  filters,
}) => {
  const rightProjectSearch = useSelector((s) => s.rightFilterState.worker.search) || '';
  const rightProjectIncludeInternalProduction = useSelector((s) => s.rightFilterState.worker.includeInternalProduction) || false;
  const rightProjectIncludeOwnProjects = useSelector((s) => s.rightFilterState.worker.includeOwnProjects) || false;
  const rightProjectOrg = useSelector((s) => s.rightFilterState.worker.org) || [];
  const rightProjectFilter = useSelector((s) => s.rightFilterState.worker.projects) || [];
  const rightProjectLeaders = useSelector((s) => s.rightFilterState.project.leaders) || [];

  const showWorkers = useSelector((state: RootState) => state.viewSetting.showWorkers);
  const showDummies = useSelector((state: RootState) => state.viewSetting.showDummies);
  const showMachines = useSelector((state: RootState) => state.viewSetting.showMachines);
  const showEquipment = useSelector((state: RootState) => state.viewSetting.showEquipment);
  const organizations = useOrganizations();
  const { data: machineDummies = [] } = useGetCategoriesQuery({ includeSubCategories: true });
  const { data: equipmentDummies = [] } = getEquipmentCategories({ includeSubCategories: true });

  const workers = page === 'project' && showWorkers ? items : [];

  const disabled = rightProjectOrg.length === 0 && organizations.length === 0;
  const filterActive = (page === 'worker' && (rightProjectOrg.length > 0 || rightProjectFilter.length > 0))
    || (page === 'project' && rightProjectLeaders.length > 0);

  const dispatch = useDispatch();
  const dummies = useDummies();
  const machines = machineDummies.map((m) => ({ id: `machine${m.name}`, label: m.name, subCategories: m.subCategories })) as Displayable[];
  const equipment = equipmentDummies.map((e) => ({ id: `equipment${e.name}`, label: e.name, subCategories: e.subCategories })) as Displayable[];

  return (
    <div className="tasks-wrapper">
      <div id="external-events">
        <div className="orders-header">
          <div className="title-wrapper">
            <div className="title">
              {page === 'project' ? 'Ansatt' : 'Prosjekt'}
              <Box display="flex" flex={1} gap={3} justifyContent="flex-end">
                {page === 'worker' && (
                <TextField
                  sx={{
                    width: rightProjectSearch ? '60%' : '30%', justifyContent: 'center', ':focus-within': { width: '60%', textAlign: 'left' }, transition: 'width 0.3s',
                  }}
                  placeholder="Søk"
                  type="search"
                  variant="standard"
                  size="small"
                  value={rightProjectSearch}
                  onChange={(e) => dispatch(setProjectSearch(e.target.value))}
                />
                )}
                {filters !== undefined && (
                <PopMenu
                  button={(
                    <IconButton color="primary" aria-label="showWeekend" component="span">
                      <FilterIcon filterActive={filterActive} />
                    </IconButton>
                )}
                  width={filters === undefined ? 150 : 300}
                >
                  <span>
                    {filters !== undefined && (
                    <span>
                      {filters}
                    </span>
                    )}
                  </span>
                </PopMenu>
                )}
              </Box>
            </div>
          </div>
          {page === 'worker' && (
          <Box display="flex" flexDirection="column" alignItems="left" marginLeft={0.1}>
            <FormControlLabel
              label={<Typography fontSize="14px">Vis egne</Typography>}
              disabled={disabled}
              control={(
                <Checkbox
                  size="small"
                  checked={disabled ? false : rightProjectIncludeOwnProjects}
                  onChange={(() => dispatch(setIncludeOwnProjects(!rightProjectIncludeOwnProjects)))}
                />
                  )}
            />
            <FormControlLabel
              label={<Typography fontSize="14px">Vis internproduksjon</Typography>}
              disabled={disabled}
              control={(
                <Checkbox
                  size="small"
                  checked={disabled ? false : rightProjectIncludeInternalProduction}
                  onChange={(() => dispatch(setIncludeInternalProduction(!rightProjectIncludeInternalProduction)))}
                />
                  )}
            />
          </Box>
          )}
        </div>

        <div className="orders-content">
          {page === 'project' && workers.sort((a, b) => (a.label.localeCompare(b.label, 'nb')))
            .concat(showDummies ? dummies : [])
            .concat(showMachines ? machines : [])
            .concat(showEquipment ? equipment : [])
            .map((i) => (
              <SidebarItem key={i.id} page={page} item={i} onClick={() => onSelect(i)} />
            ))}
          {page === 'worker' && items.sort((a, b) => bs(a.starred, b.starred)).map((i) => (
            <SidebarItem key={i.id} page={page} item={i} onClick={() => onSelect(i)} />
          ))}
          {rightLoading && (
            Array.from(Array(50).keys()).map((i) => (<div className="mt20" key={i}><LoadingSidebarItem /></div>))
          )}
        </div>
      </div>
    </div>
  );
};
