import {
  Box,
  TextField,
  Typography,
} from '@mui/material';
import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  areIntervalsOverlapping,
  endOfDay,
  formatISO,
  isBefore,
  isEqual,
  startOfDay,
} from 'date-fns';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isAfter } from 'date-fns/esm';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { Task } from '../../core/types/task';
import { PopConfirm } from '../../shared/components/popConfirm';
import {
  useDeleteLoanMutation,
  useGetOrganizationsQuery,
  useGetWorkerLoansQuery,
  usePostLoanMutation,
  usePutLoanMutation,
} from '../../core/redux/worker';
import { NOT_DELIVERED_COLOR } from '../../shared/constants';
import { ensureAfter } from '../../shared/logic/dates';
import { UpdateLoan } from '../../core/api/updateLoan';
import { SearchSelect } from '../../shared/components/searchSelect';
import { SelectItem } from '../../shared/types/util/selectItem';
import { Loan } from '../../core/types/loan';
import { Button } from '../../shared/components/button';
import { useHasRoles } from '../../core/helpers/useHasRoles';

export const EditLoan: FC<{
  loan?: Task|Loan|undefined,
  userId?: number,
  onClose?: () => void,
  startDate?: Date,
}> = ({
  loan,
  userId: rawUserId,
  onClose = () => null,
  startDate = new Date(),
}) => {
  const { data: organizations } = useGetOrganizationsQuery();

  const userId = rawUserId || loan?.worker?.employeeNumber || undefined;
  const { data: loans = [] } = useGetWorkerLoansQuery(userId || skipToken);

  const [from, setFrom] = useState<Date>(startOfDay(startDate));
  const [to, setTo] = useState<Date>(endOfDay(startDate));
  const [loaner, setLoaner] = useState<SelectItem|undefined>(undefined);
  const [comment, setComment] = useState<string>('');

  const [sendCreate, { isLoading: isCreating }] = usePostLoanMutation();
  const [sendUpdate, { isLoading: isUpdating }] = usePutLoanMutation();
  const [sendDelete, { isLoading: isDeleting }] = useDeleteLoanMutation();

  const loading = useMemo(
    () => isDeleting || isUpdating || isCreating,
    [isDeleting, isUpdating, isCreating],
  );

  useEffect(() => {
    if (!loan) return;
    setFrom(startOfDay(new Date(loan.startTime)));
    setTo(endOfDay(new Date(loan.endTime)));
    setLoaner(
      loan.loanerOrganization
        ? { id: loan.loanerOrganization.id, label: loan.loanerOrganization.name }
        : undefined,
    );
    setComment(loan.comment || '');
  }, [loan]);

  const organizationOptions = useMemo(() => (
    organizations && [...organizations]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((org) => ({ id: org.id, label: org.name }))
  ), [organizations]);

  const changeFrom = (date: Date) => {
    setFrom(startOfDay(date));
    setTo((old) => endOfDay(ensureAfter(startOfDay(old), startOfDay(date))));
  };

  const changeTo = (date: Date) => {
    setTo(endOfDay(date));
  };

  const success = (status?: 'Created' | 'Deleted') => {
    switch (status) {
      case 'Created': {
        toast.success('Utlån opprettet');
        break;
      }
      case 'Deleted': {
        toast.success('Utlån slettet');
        break;
      }
      default: {
        toast.success('Utlån lagret');
        break;
      }
    }
    onClose();
  };

  const deleteLoan = () => {
    if (!loan) return;
    sendDelete(loan.id).unwrap().then(() => success('Deleted'));
  };

  const createLoan = () => {
    if (loan !== undefined || rawUserId === undefined) return;
    if (loaner === undefined) return;
    if (typeof loaner.id !== 'number') return;
    const body: UpdateLoan = {
      startTime: formatISO(from),
      endTime: formatISO(to),
      workerId: rawUserId,
      loanerOrganizationId: loaner.id,
      comment,
    };
    sendCreate(body).unwrap().then(() => success('Created'));
  };

  const updateAbsence = () => {
    if (!loan) return;
    if (loan.worker === undefined) return;
    if (loaner === undefined) return;
    if (typeof loaner.id !== 'number') return;
    const body: UpdateLoan = {
      startTime: formatISO(from),
      endTime: formatISO(to),
      workerId: loan.worker.employeeNumber,
      loanerOrganizationId: loaner.id,
      comment,
    };
    sendUpdate({ loanId: loan.id, body }).unwrap().then(() => success());
  };

  const error = useMemo(() => {
    if (!loaner) return true;
    if (isBefore(to, from)) return 'Fra dato må være før til dato';
    if (loans.some((l) => l.id !== loan?.id && areIntervalsOverlapping(
      { start: from, end: to },
      { start: new Date(l.startTime), end: new Date(l.endTime) },
    ))) {
      if (!loan?.worker?.fullName) {
        return 'Det er allerede et utlån i denne perioden';
      }
      return `${loan?.worker?.fullName} er allerede utlånt i denne perioden.`;
    }
    return false;
  }, [from, to, loaner]);

  const today = startOfDay(new Date());
  const hasStarted = !isAfter(from, today) && loan !== undefined;
  const isAdmin = useHasRoles(['admin', 'super-admin']);
  const canDelete = loan !== undefined && (!hasStarted || isAdmin);
  const canEdit = true;

  const round = (d: Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
  const compareDates = (lhs: Date, rhs: Date) => isEqual(round(lhs), round(rhs));

  const hasChanged = useMemo(
    () => loan === undefined
      || (
        (loan !== undefined && !compareDates(from, new Date(loan?.startTime)))
        || (loan !== undefined && !compareDates(to, new Date(loan?.endTime)))
        || comment !== loan?.comment
        || loaner?.id !== loan?.loanerOrganization?.id),
    [from, to, comment, loaner, loan],
  );

  return (
    <div className="edit-absence-component">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              size="medium"
              fullWidth
              label="Fra"
              value={from}
              onChange={changeFrom}
              disableTime
              format="dd.MM.yyyy"
              disabled={hasStarted || !canEdit}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              size="medium"
              fullWidth
              label="Til"
              value={to}
              onChange={changeTo}
              minDate={from}
              disableTime
              format="dd.MM.yyyy"
              disabled={!canEdit}
            />
          </Box>
        </Box>
        <Box>
          <SearchSelect
            value={loaner}
            onChange={setLoaner}
            label="Avdeling"
            required
            errorLabel=""
            disabled={hasStarted || !canEdit}
          >
            {organizationOptions}
          </SearchSelect>
        </Box>
        <Box>
          <TextField
            label="Kommentar"
            fullWidth
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            minRows={4}
            disabled={!canEdit}
          />
        </Box>
        {error && error !== true && (
          <Typography color={NOT_DELIVERED_COLOR} align="right">{error}</Typography>
        )}
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            {canDelete && (
              <PopConfirm
                buttonProps={{ disabled: !isDeleting && loading }}
                loading={isDeleting}
                content={(
                  <>
                    Vil du slette dette utlånet?<br />
                    Det vil ikke være mulig å angre slettingen
                  </>
                )}
                onConfirm={deleteLoan}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button color="primary" variant="outlined" disabled={loading} onClick={onClose}>
              {canEdit ? 'Lukk' : 'Avbryt'}
            </Button>
            <Button
              disabled={!hasChanged || error !== false || (!(isCreating || isUpdating) && loading)}
              color="primary"
              variant="contained"
              loading={isCreating || isUpdating}
              onClick={loan === undefined ? createLoan : updateAbsence}
            >
              {loan === undefined ? 'Lån ut' : 'Lagre'}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
