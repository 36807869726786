import { useMemo } from 'react';
import { SearchSelectDirect } from '../../shared/components/searchSelect';
import { useGetAllWorkersQuery } from '../../shared/redux/rental';
import { SelectItem } from '../../shared/types/util/selectItem';

export const WorkerPicker = ({
  label = 'Velg ansatt',
  value = null,
  disabled = false,
  onChange = () => null,
}: {
  label?: string,
    value?: number | null,
    disabled?: boolean,
  onChange?: (v: number|undefined) => void,
}) => {
  const { data: workers } = useGetAllWorkersQuery();

  const options = useMemo((): SelectItem<number>[] => {
    if (!workers) return [];
    return workers.map((w): SelectItem<number> => ({
      id: w.employeeNumber,
      label: `${w.fullName ? w.fullName : 'Navn mangler'} - ${w.mobilePhoneWork ? w.mobilePhoneWork : 'Telefonnummer mangler'}`,
    }));
  }, [workers]);

  return (
    <SearchSelectDirect
      required
      disabled={disabled}
      errorLabel="Velg bestiller"
      label={label}
      value={value}
      onChange={onChange}
    >
      {options}
    </SearchSelectDirect>
  );
};
