import { v4 as uuidv4 } from 'uuid';
import {
  addHours,
  compareAsc,
  formatISO,
  subHours,
} from 'date-fns';
import { useCallback } from 'react';
import { useOrganizations } from '../redux/leftFilterState';
import { Task } from '../types/task';
import { CalendarView } from '../types/util/calendarView';
import { PRIMARY, STARTED_COLOR } from '../../shared/constants';
import { TaskType } from '../types/enums/taskType';
import { TaskStatus } from '../types/enums/taskStatus';

export const eventDefaultStart = (d: Date, v: CalendarView) => {
  if (v === 'maned') return formatISO(addHours(d, 7));
  if (v === 'kvartal') return formatISO(addHours(d, 7));
  if (v === 'ar') return formatISO(addHours(d, 7));
  return formatISO(d);
};

export const eventDefaultEnd = (d: Date, v: CalendarView) => {
  if (v === 'maned') return formatISO(subHours(d, 9));
  if (v === 'kvartal') return formatISO(subHours(d, 9));
  if (v === 'ar') return formatISO(subHours(d, 9));
  return formatISO(d);
};

/**
 * Figures out if the given event is a loan to one of the current organizations
 */
export const isLoaned = (
  {
    type,
    status,
    worker,
  }: Task,
  organizations: number[],
): boolean => {
  if (type !== 'Loan') return false;
  if (status !== 'Approved') return false;
  if (!worker) return false;
  if (organizations.includes(worker.parentOrganizationId)) return false;
  if (organizations.length === 0) return false;

  return true;
};

/**
 * Provides the organizations to isLoaned
 * @see isLoaned
 */
export const useIsLoaned = () => {
  const organizations = useOrganizations();
  const func = useCallback((task: Task) => (
    isLoaned(task, organizations)
  ), [organizations]);
  return func;
};

/**
 * Create an rtk query tag for task
 *
 * @param task Task to create tag for
 * @returns Task tag with Id
 */
export const taskIdTag = (task: Task|string|undefined) => {
  if (!task) return [];
  if (typeof task === 'string') return [{ type: 'tasks', id: task || '' }] as const;
  return [{ type: 'tasks', id: task?.id || '' }] as const;
};

/**
 * Finds the latest task in a list of tasks.
 *
 * Defaults to first entry if equal
 *
 * @param tasks Tasks to search through
 * @returns Latest task
 */
export const latestAssignment = (tasks: Task[]): Task => {
  let newestDate = null;
  let newestAssignment = tasks[0];
  for (let i = 0; i < tasks.length; i += 1) {
    const task = tasks[i];
    if (newestDate === null || compareAsc(new Date(task.lastEditTime), newestDate) > 0) {
      newestDate = new Date(task.lastEditTime);
      newestAssignment = task;
    }
  }
  return newestAssignment;
};

/**
 * Checks if a given task is editable
 *
 * @param task Task to check
 * @returns True if editable, false otherwise
 */
export const canEdit = (task: Task): boolean => {
  if (task.status === 'Deleted') return false;
  return true;
};

export const generateTaskId = () => `${uuidv4()}-${new Date().getTime()}`;

const extUnavailabilityBackgroundOpacity = (p: number) => {
  if (p <= 20) return '33';
  if (p <= 40) return '66';
  if (p <= 60) return '99';
  if (p <= 80) return 'CC';
  return 'FF';
};
export const eventBackgroundColor = (id: number | undefined, type: TaskType, status: TaskStatus, sickPercentage?: number) => {
  if (status === 'New') return STARTED_COLOR;
  if (type === 'ExternalUnavailability' && sickPercentage) return `#ffde00${extUnavailabilityBackgroundOpacity(sickPercentage)}`;
  if (type === 'ExternalUnavailability') return '#ffde00';
  if (type === 'Unavailability') return '#fbc011';
  if (type === 'Loan') {
    if (status === 'Approved') {
      return '#666666';
    }
    return '#aaaaaa';
  }
  if (id) return PRIMARY;
  return '#ec7c0c';
};
