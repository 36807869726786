import { useMemo } from 'react';
import {
  formatISO,
  subYears,
  startOfHour,
  addYears,
  isBefore,
  isAfter,
  compareAsc,
} from 'date-fns';
import { useSelector } from '../../../core/hooks/redux';
import { Displayable } from '../../../shared/types/util/displayable';
import { useGetAllTasksQuery } from '../../../core/redux/worker';
import { Task } from '../../../core/types/task';

const emptyArray: never[] = [];

const getNum = (v: number|string) => {
  if (typeof v === 'number') return v;
  return parseInt(v, 10) || 0;
};

export const useSorter = () => {
  const sortBy = useSelector((s) => s.leftFilterState.worker.sortBy ?? 'stucture');
  const { data: allAssignments = emptyArray } = useGetAllTasksQuery({
    from: formatISO(subYears(startOfHour(new Date()), 1)),
    to: formatISO(addYears(startOfHour(new Date()), 1)),
  });

  const vehicleProjects = useMemo(() => {
    if (sortBy !== 'project') return new Map<number, number>();
    /*
    Priority:
    1. Current assignment
    2. Earliest assignment (by start time)
    */
    const now = new Date();

    const isNow = (a: Task) => {
      if (isBefore(new Date(a.startTime), now)) return false;
      if (isAfter(new Date(a.endTime), now)) return false;
      return true;
    };
    const sorted = [...allAssignments].sort((a, b) => {
      const aIsNow = isNow(a);
      const bIsNow = isNow(b);
      if (aIsNow && !bIsNow) return 1;
      if (!aIsNow && bIsNow) return -1;
      // Both either are today or are not today, take the one that starts
      // latest, it is most likely to be the actual (?)
      return compareAsc(new Date(a.startTime), new Date(b.startTime));
    });

    const final = new Map<number, Task>();
    sorted.forEach((a) => {
      if (!a.worker) return;
      if (!a.project) return;

      if (isBefore(new Date(a.endTime), now)) return;
      if (final.has(a.worker.employeeNumber)) return;

      final.set(a.worker.employeeNumber, a);
    });
    return new Map(Array.from(final).map(([key, value]) => [key, value.project?.id]));
  }, [allAssignments, sortBy]);

  const sortFunc = useMemo((): (a: Displayable, b: Displayable) => number => {
    if (sortBy === 'project') {
      return (a, b) => (
        (vehicleProjects.get(typeof a.id === 'number' ? a.id : parseInt(a.id, 10)) || Infinity)
        - (vehicleProjects.get(typeof b.id === 'number' ? b.id : parseInt(b.id, 10)) || Infinity)
      );
    }
    if (sortBy === 'mark') {
      return (a, b) => (
        a.tag && b.tag
          ? a.tag.label.localeCompare(b.tag.label, 'nb')
          : ((b.tag ? 1 : 0) - (a.tag ? 1 : 0))
      );
    }
    if (sortBy === 'id') return (a, b) => getNum(a.id) - getNum(b.id);

    if (sortBy === 'name') return (a, b) => a.label.localeCompare(b.label);

    return (a, b) => {
      if (a.path === undefined || b.path === undefined) return 0;
      return a.path.localeCompare(b.path, 'nb');
    };
  }, [sortBy, vehicleProjects]);
  return sortFunc;
};
