import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import { useEffect, useState } from 'react';
import { ColorPicker } from './colorPicker';
import { PopConfirm } from '../../../shared/components/popConfirm';
import { Tag } from '../../../shared/types/tag';
import {
  useDeleteTagMutation,
  usePostTagMutation,
  usePutTagMutation,
} from '../../../core/redux/worker';

export const TagModalContent = ({
  tags,
  tagsLoading = false,
} : {
  tags?: Tag[],
  tagsLoading?: boolean
}) => {
  const [deleteTag, { isLoading: isDeleting }] = useDeleteTagMutation();
  const [putTag, { isLoading: isPutting }] = usePutTagMutation();
  const [postTag, { isLoading: isPosting }] = usePostTagMutation();

  const [tagLabels, setTagLabels] = useState<{id: number, label: string}[]|[]>();

  useEffect(() => {
    if (tags) {
      setTagLabels(tags.map((t) => ({ id: t.id, label: t.label })));
    }
  }, [tags]);

  return (
    <Box margin={2} maxWidth="800px">
      <Box display="flex" flexDirection="column" gap={2} maxHeight="500px" overflow="auto">
        <Box display="flex" marginBottom={1} gap={2}>
          <Typography fontSize="18px" flex={1}>Merke</Typography>
          <Typography fontSize="18px" flex={1}>Farge</Typography>
        </Box>
        {tags?.map((t) => {
          const val = tagLabels?.find((tl) => tl.id === t.id)?.label;
          return (
            <Box display="flex" gap={2}>
              <Box display="flex" flex={1}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={val}
                  onBlur={() => val?.trim().length === 0 && setTagLabels(tagLabels?.map((tl) => (tl.id === t.id ? { ...tl, label: t.label } : tl)))}
                  onChange={(e) => setTagLabels(tagLabels?.map((tl) => (tl.id === t.id ? { ...tl, label: e.currentTarget.value } : tl)))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {val !== t.label && (
                        <Tooltip
                          title={<Typography fontSize={14}>Tilbakestill</Typography>}
                        >
                          <IconButton disabled={isPutting} onClick={() => setTagLabels(tagLabels?.map((tl) => (tl.id === t.id ? { ...tl, label: t.label } : tl)))}>
                            <ReplayIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        )}
                        {val && val !== t.label && val.trim().length > 0 && (
                        <Tooltip
                          title={<Typography fontSize={14}>Lagre</Typography>}
                        >
                          <IconButton disabled={isPutting} onClick={() => putTag({ id: t.id, body: { ...t, label: val } })}>
                            <SaveIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box display="flex" flex={1}>
                <ColorPicker
                  color={t.background}
                  isLoading={isPutting}
                  onChange={(bg, fg) => {
                    (
                      putTag({
                        id: t.id,
                        body: { ...t, background: bg, foreground: fg },
                      })
                    );
                  }}
                />
                <PopConfirm
                  buttonProps={{ disabled: isDeleting }}
                  buttonText={(
                    <Tooltip
                      title={<Typography fontSize={14}>Slett</Typography>}
                    >
                      <DeleteIcon sx={{ color: '#484848' }} />
                    </Tooltip>
                )}
                  buttonColor="inherit"
                  buttonVariant="text"
                  loading={isDeleting}
                  content={(
                    <>
                      Det vil ikke være mulig å angre slettingen
                    </>
                  )}
                  onConfirm={() => deleteTag(t.id)}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box marginTop={2}>
        <Tooltip
          placement="right"
          title={<Typography fontSize={14}>Ny merknad</Typography>}
        >
          <IconButton onClick={() => postTag({ label: 'Ny merknad', background: '#414bb2', foreground: '#ffffff' })}>
            <AddIcon sx={{ fontSize: 40, color: '#484848' }} />
          </IconButton>
        </Tooltip>
      </Box>
      {(tagsLoading || isPosting || isPutting || isDeleting) && (
        <CircularProgress
          size={75}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
            zIndex: 1000,
          }}
        />
      )}
    </Box>
  );
};
