import {
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FullC from '@fullcalendar/react'; // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import nbLocale from '@fullcalendar/core/locales/nb';
import {
  addHours,
  endOfYear,
  format,
  formatISO,
  getHours,
  getWeek,
  startOfDay,
  startOfYear,
} from 'date-fns';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { EventContentArg, EventInput } from '@fullcalendar/core';
import { ColSpec } from '@fullcalendar/resource';
import { useNavigate } from 'react-router-dom';
import { useDate } from '../../core/hooks/useDate';
import { PageName } from '../../core/types/util/pageName';
import { useColumns } from '../../core/redux/leftFilterState';
import { HoverEvent } from '../hoverEvent';
import { Event } from '../../core/types/util/event';
import { useGetCalendarQuery } from '../../core/redux/worker';
import { translateColumnName } from '../../core/helpers/translate';
import { useTimeframe } from '../../core/hooks/useTimeframe';
import { Displayable } from '../../shared/types/util/displayable';
import { leftPad } from '../../shared/logic/functions';
import { shadowEvent } from '../../shared/logic/shadowEvent';
import { EventCommentIcon } from '../../shared/components/eventCommentIcon';
import { TagButton } from '../tagColumn/partials/tagButton';
import { useSelector } from '../../core/hooks/redux';
import logo from '../../assets/stangeland-logo-blue.png';
import './style.scss';
import { useSorter } from '../planner/hooks/useSorter';

export const PrintablePlanner: FC<{
  page: PageName;
  tasks: Event[];
  left: Displayable[];
  leftLoading?: boolean;
  leftFilters?: ReactNode;
}> = ({
  page,
  tasks,
  left,
  leftLoading = false,
  leftFilters,
}) => {
  const [date] = useDate(page);
  const view = 'uke';
  const dataColumns = useColumns(page);
  const showWeekend = useSelector((state) => state.viewSetting.showWeekend);
  const showCoreHours = useSelector((state) => state.viewSetting.showCoreHours);
  const tagColumnActive = useSelector((state) => state.leftFilterState.worker.columns.includes('tag'));
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const timeframe = useTimeframe();

  const { data: nonWorkingDays } = useGetCalendarQuery({
    from: formatISO(startOfYear(date)),
    to: formatISO(endOfYear(date)),
  });

  const calRef: RefObject<FullC> = useRef<FullC>(null);
  const calApi = useMemo(
    () => (calRef.current ? calRef.current.getApi() : null),
    [calRef.current],
  );

  useEffect(() => {
    if (calApi) calApi.changeView(view);
  }, [calApi, view]);

  useEffect(() => {
    if (calApi) calApi.gotoDate(date);
  }, [calApi, date]);

  useEffect(() => setFirstRun(false), []);

  useEffect(() => {
    if (calApi) setTimeout(() => calApi.trigger('_resize', true), 100);
  }, [left, tasks]);

  const holidays = useMemo(() => {
    if (!nonWorkingDays) return [];
    return nonWorkingDays.map(
      (n): EventInput => ({
        id: n.date,
        start: addHours(startOfDay(new Date(n.date)), 7),
        end: addHours(startOfDay(new Date(n.date)), 15),
        display: 'background',
        color: 'rgb(215, 215, 215)',
      }),
    );
  }, [nonWorkingDays]);

  const FCEvents = useMemo(() => {
    calApi?.removeAllEvents();
    return [
      ...tasks.map(
        (t): EventInput => ({
          ...t,
          id: `${t.id}`,
          title: t.label,
          start: t.start,
          end: t.end,
          resourceId: `${t.parent}`,
          borderColor: t.color,
          backgroundColor: t.ghost === true ? 'rgba(0,0,0,0)' : t.color,
          textColor:
            t.ghost === true
            || t.color.substring(0, 7) === '#ffde00'
            || t.color === '#fbc011'
              ? 'black'
              : 'white',
          classNames: t.color === '#fbc011' ? ['bold-text'] : [],
          groupId: t.reverseBackground === true ? 'reverse' : `${t.id}`,
          display:
            t.reverseBackground === true ? 'inverse-background' : undefined,
        }),
      ),
      ...holidays,
      shadowEvent,
    ];
  }, [tasks, holidays]);

  const sortFn = useSorter();

  const FCResources = useMemo(() => {
    if (leftLoading) {
      return Array.from(Array(51).keys())
        .slice(1)
        .map((i) => ({
          id: `${i}`,
          title: `${i}`,
        }));
    }
    const sorted = left.sort(sortFn);
    return sorted.map((l, i) => ({
      ...l,
      id: `${l.id}`,
      title: l.dummy === true ? l.label : `${l?.id} ${l.label}`,
      dummy: l.dummy === true,
      sortOrder:
          page === 'worker' ? leftPad(`${i}`, 10) : leftPad(`${l.id}`, 10),
    }));
  }, [leftLoading, left, tagColumnActive, sortFn]);

  const FCColumns = useMemo(
    () => dataColumns.map(
      (c): ColSpec => ({
        field: c,
        headerContent: translateColumnName(c, true),
        width: c === 'id' || c === 'responsible_id' ? 70 : undefined,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellContent: (e) => {
          if (c === 'id') {
            if (Number.isNaN(parseInt(e.resource?.id || '0', 10))) return '';
            return true;
          }
          if (c === 'label' && page === 'worker' && e.resource?.id) {
            return (
              <span className="line-wrapper">
                <span className="one-line-ellipse">
                  {e.resource?.title.replace(/^\d+ /, '')}
                </span>
              </span>
            );
          }
          if (c === 'tag' && page === 'worker' && e.resource?.id) {
            if (Number.isNaN(parseInt(e.resource?.id || '0', 10))) return '';
            const worker = left.find((l) => `${l.id}` === `${e.resource?.id}`);
            return (
              <TagButton worker={worker} defaultText="" />
            );
          }
          return true;
        },
      }),
    ),
    [dataColumns, left],
  );

  const navigate = useNavigate();

  const leftHeader = useMemo(
    () => (
      <Box sx={{
        paddingX: 2, display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between',
      }}
      >
        <IconButton
          onClick={() => { document.title = ('Mannskapsplanlegger'); navigate(-1); }}
          sx={{ borderRadius: 6, transform: 'scaleX(1.3)' }}
        >
          <img
            className="stangeland-logo-printable"
            src={logo}
            alt="Stangeland logo"
          />
        </IconButton>
        <Typography sx={{
          fontSize: 22,
          fontWeight: 'bold',
          textTransform: 'capitalize',
          color: '#222',
        }}
        >
          { `uke ${getWeek(date)} ${format(date, 'yyyy')}`}
        </Typography>
      </Box>
    ),
    [page, leftFilters],
  );

  const eventContent = useCallback(
    (e: EventContentArg) => {
      const event = tasks.find((t) => `${t.id}` === e.event.id);
      let { title } = event?.isMachine ? event : e.event;
      let isComment = !!event?.comment;
      if (!event) {
        // Do nothing
      } else if (event.type === 'ExternalUnavailability') {
        title = event.sickPercentage
          ? `Fravær ${event.sickPercentage}%`
          : 'Fravær';
        isComment = false;
      } else if (event.type === 'Unavailability') {
        title = 'Avtalt Fravær';
      }
      if (event?.reverseBackground === true) {
        return (
          <div className="fc-event-main-frame">
            <span className="fc-event-title fc-sticky">{title}</span>
          </div>
        );
      }
      return (
        <Tooltip
          title={event ? <HoverEvent task={event} page={page} /> : ''}
          followCursor
          placement="right-start"
          enterDelay={1000}
          enterNextDelay={1000}
          arrow
        >
          <div className="fc-event-main-frame">
            {isComment && <EventCommentIcon />}
            <span className="fc-event-title fc-sticky">{title}</span>
          </div>
        </Tooltip>
      );
    },
    [tasks],
  );

  return (
    <div className="printable-planner-wrapper">
      <div
        className={`calendar-inner${
          view === 'uke' || view === 'kvartal' || view === 'ar' ? ' fc-hide-lines' : ''
        }`}
      >
        <FullC
          ref={calRef}
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          initialView="uke"
          initialDate={date}
          resources={FCResources}
          resourceAreaColumns={FCColumns}
          slotLaneClassNames={(a) => {
            if (!a.date) return '';
            const hour = getHours(a.date);
            if ([7, 15, 23].includes(hour)) return 'show-slot-divider';
            return '';
          }}
          resourceLabelDidMount={({ resource, el }) => {
            if (el?.parentElement) {
              // eslint-disable-next-line no-param-reassign
              el.parentElement.className = `resource-style ${
                page === 'worker' && resource.extendedProps.dummy
                  ? 'dummy'
                  : null
              }`;
            }
          }}
          resourceAreaHeaderContent={leftHeader}
          resourceOrder="dummy, sortOrder"
          events={FCEvents}
          eventContent={eventContent}
          headerToolbar={false}
          resourceAreaWidth={300 + dataColumns.length * 50}
          locale={nbLocale}
          scrollTimeReset={false}
          height="auto"
          displayEventTime={false}
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '07:00',
            endTime: '15:00',
          }}
          visibleRange={
              firstRun
                ? undefined
                : {
                  start: timeframe.from,
                  end: timeframe.to,
                }
            }
          slotLabelClassNames="slot-label"
          views={{
            uke: {
              type: 'resourceTimeline',
              slotDuration: { hours: 1 },
              slotLabelFormat: [
                { weekday: 'short', day: '2-digit', month: 'short' },
                { hour: '2-digit', minute: '2-digit' },
              ],
              slotLabelContent: (l) => {
                const hourLabel = showCoreHours ? '07:00 - 15:00' : l.text;
                return (l.level !== 0
                  ? hourLabel
                  : l.text.slice(0, 2)
                    + l.text.slice(3, l.text.length).replace(/\.$/, ''));
              },
              slotLabelInterval: { hours: 8 },
              slotLabelClassNames: showCoreHours ? 'week-slot-hour-label' : '',
              snapDuration: { hours: 8 },
              slotMinWidth: 6,
              weekends: showWeekend,
              slotMinTime: { hours: showCoreHours ? 7 : -1 },
              slotMaxTime: { hours: showCoreHours ? 15 : 23 },
            },
          }}
            // Not a secret since it can easily be spotted in minified files
            // no matter where or how it is stored
          schedulerLicenseKey="0067403127-fcs-1701517614"
        />
      </div>
    </div>
  );
};
