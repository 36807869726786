export interface MachineEditRules {
  // can edit
  editTime: boolean,
  editProject: boolean,
  editMachine: boolean,
  editSubCat: boolean,
  editComment: boolean,
  editOrderComment: boolean,
  editInternalComment: boolean,

  // show jsx/component
  seeMachine: boolean,
  seeMainCat: boolean,
  seeSubCat: boolean,
  seeComment: boolean,
  seeOrderComment: boolean,
  seeInternalComment: boolean,
  seeLastEditBy: boolean,
  seeApprovedBy: boolean,
  seeOrderCard: boolean,

  // buttons
  seeAssignMachineButton: boolean,
  seeApproveMachineButton: boolean,
  seeCancelButton: boolean,
  seeSaveButton: boolean,

  // text
  closeButtonText: string,
  cancelButtonText: string,
}

export const machineEditRulesDefaults: MachineEditRules = {
// can edit
  editTime: false,
  editProject: false,
  editMachine: false,
  editSubCat: false,
  editComment: false,
  editOrderComment: false,
  editInternalComment: false,

  // show jsx/component
  seeMainCat: false,
  seeSubCat: false,
  seeMachine: false,
  seeComment: false,
  seeOrderComment: false,
  seeInternalComment: false,
  seeLastEditBy: false,
  seeApprovedBy: false,
  seeOrderCard: false,

  // buttons
  seeAssignMachineButton: false,
  seeApproveMachineButton: false,
  seeCancelButton: false,
  seeSaveButton: false,

  // text
  closeButtonText: 'Lukk',
  cancelButtonText: 'Kanseller',
};
