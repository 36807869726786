import { ReactNode } from 'react';
import { useHasRoles } from '../../core/helpers/useHasRoles';
import { Roles } from '../../shared/types/enums/workerRoles';

/**
 * Renders child component if user is a part any of the provided roles.
 */
export const HasRoleTemplate = ({
  children,
  roles,
}: {
  children?: ReactNode;
  roles: Roles[] | Roles;
}) => {
  const shouldShow = useHasRoles(roles);
  if (!shouldShow) return null;
  return (<span>{children}</span>);
};
