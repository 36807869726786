import { FC } from 'react';
import { useGetProjectQuery, usePutFavouriteMutation } from '../../core/redux/worker';
import { Favourite } from '../../shared/components/favourite';

export const ProjectFavourite: FC<{
  projectId: number,
}> = ({
  projectId,
}) => {
  const { data: project } = useGetProjectQuery(projectId);
  const [update, { isLoading: isUpdating }] = usePutFavouriteMutation();
  return (
    <Favourite
      enabled={project?.favourite}
      loading={isUpdating}
      onClick={() => { update({ id: projectId, enabled: !project?.favourite }); }}
    />
  );
};
