import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../core/redux/index';
import { useGetMyOrganizationQuery } from '../../../core/redux/worker';
import { setDefaultOrganization } from '../../../core/redux/leftFilterState';

export const useSetDefaultFilters = () => {
  const defaultLeftSet = useSelector((s: RootState) => s.leftFilterState.defaultSet);
  const [requested, setRequested] = useState<boolean>(false);
  const { data: myOrg, isFetching } = useGetMyOrganizationQuery(undefined, { skip: requested });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFetching) {
      setRequested(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (myOrg) {
      if (!defaultLeftSet) {
        dispatch(setDefaultOrganization(myOrg.id));
      }
    }
  }, [defaultLeftSet, myOrg, dispatch]);
};
