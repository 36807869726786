import { AssignmentType } from '../types/enums/assignmentType';
import { AssignmentStatus } from '../types/enums/assignmentStatus';

export const getOrderStatusType = (currStatus: AssignmentStatus | undefined, type: AssignmentType) => {
  if (type === 'Order') {
    return 'Opprettet';
  }
  switch (currStatus) {
    case 'Created':
      return 'Tildelt';
    case 'Approved':
      return 'Godkjent';
    case 'NotApproved':
      return 'Ikke godkjent';
    case 'Cancelled':
      return 'Kansellert';
    default:
      return currStatus;
  }
};

export const getOrderStatusColor = (currStatus: AssignmentStatus | undefined, type: AssignmentType) => {
  if (type === 'Order') {
    return 'assigned-color';
  }
  switch (currStatus) {
    case 'Created':
      return 'created-color';
    case 'Approved':
      return 'approved-color';
    case 'NotApproved':
      return 'not-approved-color';
    case 'Cancelled':
      return 'cancelled-color';
    default:
      return currStatus;
  }
};
