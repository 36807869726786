import { FC, useMemo } from 'react';
import { Task } from '../../../core/types/task';
import { EditAbsence } from '../../editAbsence';
import { EditLoan } from '../../editLoan';
import { EditTask } from '../../editTask';
import { Modal } from '../../../shared/components/modal';

export const TaskModal: FC<{
  task: Task|null,
  onRemove?: (id: string) => Promise<Task>,
  onClose?: () => void,
}> = ({
  task,
  onRemove,
  onClose = () => null,
}) => {
  const modalContent = useMemo(() => {
    if (!task) return null;
    if (task.type === 'Loan') {
      return <EditLoan loan={task} onClose={onClose} />;
    }
    if (task.type === 'Unavailability') {
      return <EditAbsence task={task} onClose={onClose} />;
    }
    if (task.type === 'Normal') {
      return <EditTask task={task} onRemove={onRemove} onClose={onClose} />;
    }
    return null;
  }, [task]);
  const modalHeader = useMemo(() => {
    if (!task) return '';
    if (task.type === 'Loan') {
      return (
        `${task.status === 'Approved' ? 'Utlån' : 'Rediger utlån'} av ${task.worker?.fullName} (${task.worker?.employeeNumber})`
      );
    }
    if (task.type === 'Unavailability') {
      return `Rediger fravær for ${task.worker?.fullName} (${task.worker?.employeeNumber})`;
    }
    if (task.type === 'Normal') {
      return 'Rediger oppgave';
    }
    return '';
  }, [task]);

  if (!task || modalContent == null) return null;
  return (
    <Modal onClose={onClose} title={modalHeader} maxWidth="sm">
      {modalContent}
    </Modal>
  );
};
