import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.min.css';
import { ThemeProvider } from '@emotion/react';
import nbLocale from 'date-fns/locale/nb';
import { createTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, nbNO } from '@mui/x-date-pickers';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { MsalProvider } from '@azure/msal-react';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Router } from './router';
import reportWebVitals from './reportWebVitals';
import { msalInstance } from './shared/msal';
import { store } from './core/redux';
import './index.scss';
import './shared/global.scss';

const persistor = persistStore(store);

const theme = createTheme({
  palette: {
    primary: {
      main: '#004B8B',
    },
    secondary: {
      main: '#ffffff',
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={nbLocale}
    localeText={nbNO.components.MuiLocalizationProvider.defaultProps.localeText}
  >
    <MsalProvider instance={msalInstance}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Router />
            <ToastContainer />
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </MsalProvider>
  </LocalizationProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
