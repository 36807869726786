import { FC } from 'react';
import {
  Box,
} from '@mui/material';
import { format } from '../../shared/logic/dates';
import { PageName } from '../../core/types/util/pageName';
import { Event } from '../../core/types/util/event';
import './style.scss';

export const HoverEvent:FC<{
  task: Event,
  page: PageName,
}> = ({
  task,
  page,
}) => {
  if (!task) return null;

  if (task.isMachine || task.isEquipment) {
    return (
      <Box>
        <div>
          <div className="header mb-m">
            <span>ProsjektID: </span>
            <span className="bold">{`${page === 'project' ? task.parent : task.origin}`}</span>
          </div>
          <div className="header mb-m">
            <span>Kategori: </span>
            <span className="bold">{task.itemMainCategory} - </span>
            <span className="bold">{task.origin}</span>
          </div>
          <div className="content mb-m">
            <span>Tidsrom: </span>
            <span>{`${format(task.start, 'dd.MM.yyyy')}`}</span>
            <span>{' - '}</span>
            <span>{`${format(task.end, 'dd.MM.yyyy')}`}</span>
          </div>
          {task.isEquipment && task.dailyRentalPrice && (
          <div className="content mb-m">
            <span>Dagsleiepris: </span>
            <span>{`${task.dailyRentalPrice} kr/dag`}</span>
          </div>
          )}
          <div className="content mb-s">
            <span>Bestillingskommentar: </span>
            <span>{task.comment}</span>
          </div>
        </div>
      </Box>
    );
  }

  if (task.type === 'Loan') {
    return (
      <Box>
        <div>
          <div className="header mb-m">
            <span className="bold">
              {task.comment || null}
            </span>
          </div>
          <div className="content mb-s">
            <span>Tidsrom: </span>
            <span>{`${format(task.start, 'dd.MM.yyyy')}`}</span>
            <span>{' - '}</span>
            <span>{`${format(task.end, 'dd.MM.yyyy')}`}</span>
          </div>
        </div>
      </Box>
    );
  }

  if (task.type && (task.type === 'ExternalUnavailability' || task.type === 'Unavailability')) {
    return (
      <Box>
        <div>
          <div className="header mb-m">
            <span className="bold">{task.type === 'ExternalUnavailability'
              ? `${task.title.split('-')[1]} ${task.sickPercentage ? `${task.sickPercentage}%` : ''}`
              : task.comment || null}
            </span>
          </div>
          <div className="content mb-s">
            <span>Tidsrom: </span>
            <span>{task.type === 'Unavailability' ? `${format(task.start, "dd.MM 'kl.' HH:mm")}` : `${format(task.start, 'dd.MM.yyyy')}`}</span>
            <span>{' - '}</span>
            <span>{task.type === 'Unavailability' ? `${format(task.end, "dd.MM 'kl.' HH:mm")}` : `${format(task.end, 'dd.MM.yyyy')}`}</span>
          </div>
        </div>
      </Box>
    );
  }

  if (!parseInt(task.origin, 10) || !parseInt(task.parent, 10)) {
    return (
      <Box>
        <div>
          <div className="header mb-m">
            <span>ProsjektID: </span>
            <span className="bold">{`${page === 'project' ? task.parent : task.origin}`}</span>
          </div>
          <div className="header mb-m">
            <span>Arbeidstype: </span>
            <span className="bold">{`${page === 'project' ? task.origin : task.parent}`}</span>
          </div>
          <div className="content mb-m">
            <span>Tidsrom: </span>
            <span>{`${format(task.start, 'dd.MM.yyyy')}`}</span>
            <span>{' - '}</span>
            <span>{`${format(task.end, 'dd.MM.yyyy')}`}</span>
          </div>
          <div className="content mb-s">
            <span>{task.comment}</span>
          </div>
        </div>
      </Box>
    );
  }

  return (
    <Box>
      <div>
        <div className="header mb-m">
          <span>ProsjektID: </span>
          <span className="bold">{`${page === 'project' ? task.parent : task.origin}`}</span>
        </div>
        <div className="header mb-m">
          <span>AnsattID: </span>
          <span className="bold">{`${page === 'project' ? task.origin : task.parent}`}</span>
        </div>
        <div className="content mb-m">
          <span>Tidsrom: </span>
          <span>{`${format(task.start, "dd.MM 'kl.' HH:mm")}`}</span>
          <span>{' - '}</span>
          <span>{`${format(task.end, "dd.MM 'kl.' HH:mm")}`}</span>
        </div>
        <div className="content mb-s">
          <span>{task.comment}</span>
        </div>
      </div>
    </Box>
  );
};
