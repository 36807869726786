import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  Skeleton,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { UpdateProject } from '../../core/api/updateProject';
import { format } from '../../shared/logic/dates';
import {
  useGetOrganizationsQuery,
  useGetProjectQuery,
  usePutProjectBodyMutation,
} from '../../core/redux/worker';
import './style.scss';

export const ProjectDetails: FC<{id: number}> = ({ id }) => {
  const { data: organizations } = useGetOrganizationsQuery();
  const staticProductions = organizations?.filter((o) => o.internalProduction).sort((a, b) => a.name.localeCompare(b.name, 'nb')) || [];

  const [internalProduction, setInternalProduction] = useState<{id: number, checked: boolean}[]>(
    staticProductions.map((sp) => ({ id: sp.id, checked: false })),
  );

  const setSpesific = (orgId: number, checked: boolean) => {
    setInternalProduction((old) => old.map((o) => (o.id === orgId ? { id: orgId, checked } : o)));
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [spinner, setSpinning] = useState<boolean>(false);

  const { data: project, isLoading } = useGetProjectQuery(id);

  const [update] = usePutProjectBodyMutation();

  useEffect(() => {
    setInternalProduction((old) => old.map((o) => ({
      id: o.id,
      checked: project?.internalProduction.some((i) => i.id === o.id) || false,
    })));
    setIsEdit(false);
    setSpinning(false);
  }, [project]);

  const contactPerson = useMemo(() => {
    if (project?.contact?.name == null) return 'Mangler kontaktperson';
    let value = project.contact.name;
    if (project.contact.phone != null) {
      value += ` (${project.contact.phone})`;
    }
    if (project.contact.eMail != null) {
      value += `\n${project.contact.eMail}`;
    }

    return value;
  }, [project]);

  const updateInternalProduction = () => {
    if (!project) return;
    setIsEdit(false);
    setSpinning(true);

    const toSend = internalProduction.filter((i) => i.checked).map((i) => ({
      id: i.id,
      name: staticProductions.find((sp) => i.id === sp.id)?.name || '',
    }));

    const data: UpdateProject = {
      internalProduction: toSend,
    };
    update({ id: project.id, data }).unwrap().then(() => {
      toast.success('Prosjekt oppdatert');
    });
  };

  if (!project || isLoading) {
    return (
      <div className="project-details">
        <div>
          <div className="flexparent">
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton className="margin-left-230" variant="rectangular" height={35} width={200} />
          </div>
          <span className="m10 " />
          <div className="flexparent mr10">
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton className="margin-left-230" variant="rectangular" height={35} width={200} />
          </div>
          <span className="m10" />
          <div className="flexparent mr10">
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton className="margin-left-230" variant="rectangular" height={35} width={200} />
          </div>
          <span className="m10" />
          <div className="flexparent mr10">
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton className="margin-left-230" variant="rectangular" height={35} width={200} />
          </div>
          <span className="m10" />
          <div className="mr20">
            <Skeleton variant="rectangular" height={100} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Box display="flex" alignItems="flex-start">
        <Box flex={1} display="flex" flexDirection="column" gap={1}>
          <TextField
            fullWidth
            value={!project.startDate
              ? 'Ingen data'
              : format(new Date(project.startDate), 'dd.MM.yyyy')}
            label="Start dato"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
          <TextField
            fullWidth
            value={!project.expectedEndDate
              ? 'Ingen data'
              : format(new Date(project.expectedEndDate), 'dd.MM.yyyy')}
            label="Forventet slutt dato"
            multiline
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
          <TextField
            fullWidth
            value={!project.responsible?.fullName
              ? 'Ingen data'
              : project.responsible?.fullName}
            label="Ansvarlig"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!project.operationsManager?.fullName
              ? 'Ingen data'
              : project.operationsManager?.fullName}
            label="Driftsleder"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </Box>
        <Box flex={1} display="flex" flexDirection="column" gap={1}>
          <TextField
            fullWidth
            value={!project.builder
              ? 'Ingen data'
              : project.builder}
            label="Byggherre"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!project.client
              ? 'Ingen data'
              : project.client}
            label="Oppdragsgiver"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            multiline
            value={contactPerson.replace(/\n+$/, '')}
            label="Kontaktperson"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!project.contractAmount
              ? 'Ingen data'
              : `${project.contractAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} NOK`}
            label="Kontraktsum"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <InputLabel sx={{
            pt: 0,
            mt: 0,
            lineHeight: '16px',
            fontSize: 13,
          }}
          >
            Internproduksjon
          </InputLabel>
          {staticProductions.map((s) => {
            const ip = internalProduction.find((i) => i.id === s.id);
            const disabled = project.internalProduction.some((p) => p.id === s.id && p.editable);
            return (
              <FormControlLabel
                key={s.id}
                label={s.name}
                control={(
                  <Checkbox
                    checked={ip?.checked || false}
                    disabled={disabled}
                    onChange={(_, v) => { setSpesific(s.id, v); setIsEdit(true); }}
                  />
                        )}
              />
            );
          })}
        </Box>
      </Box>
      <TextField
        fullWidth
        value={!project.scopeOfWork
          ? 'Ingen data'
          : project.scopeOfWork}
        label="Arbeidsomfang"
        variant="standard"
        multiline
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <Box textAlign="right">
        <Button variant="contained" disabled={!isEdit} onClick={updateInternalProduction}>
          {spinner ? <CircularProgress size={24} /> : 'Lagre'}
        </Button>
      </Box>
    </div>
  );
};
