import { FC, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isBefore } from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDate } from '../../../core/hooks/useDate';
import { Modal } from '../../../shared/components/modal';
import { EditLoan } from '../../editLoan';
import { Worker } from '../../../core/types/worker';
import { useGetWorkerLoansQuery } from '../../../core/redux/worker';
import { timestringFormat } from '../../../shared/logic/dates';

export const Loans: FC<{
  worker?: Worker|undefined,
}> = ({
  worker,
}) => {
  const { data: loans = [] } = useGetWorkerLoansQuery(worker?.employeeNumber || skipToken);
  const [absenceModalOpen, setAbsenceModalOpen] = useState<boolean>(false);
  const [editLoanId, setEditLoanId] = useState<string|undefined>(undefined);
  const [date] = useDate();
  const closeModal = () => setAbsenceModalOpen(false);

  const editLoan = useMemo(() => {
    if (editLoanId) {
      return loans.find((l) => l.id === editLoanId);
    }
    return undefined;
  }, [editLoanId, loans]);

  const rows = useMemo(() => (
    [...loans].sort((a, b) => a.startTime.localeCompare(b.startTime, 'nb')).map((row) => (
      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{timestringFormat(row.startTime, 'dd.MM.yyyy')}</TableCell>
        <TableCell>{timestringFormat(row.endTime, 'dd.MM.yyyy')}</TableCell>
        <TableCell>{row.loanerOrganization?.name}</TableCell>
        <TableCell>{row.comment}</TableCell>
        <TableCell>{row.status === 'Approved' ? 'Godkjent' : 'Ikke akseptert'}</TableCell>
        <TableCell sx={{ paddingY: 0 }}>
          <Button size="small" onClick={() => setEditLoanId(row.id)}>
            {row.status === 'Approved' ? 'Vis' : 'Rediger'}
          </Button>
        </TableCell>
      </TableRow>
    ))), [loans]);

  return (
    <Box>
      <TableContainer sx={{ minHeight: '200px', maxHeight: '600px' }}>
        <Table sx={{ minWidth: 650 }} stickyHeader size="medium" aria-label="Personutleie">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Fra</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Til</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Utlånt til</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Kommentar</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="footer" sx={{ pt: 2 }}>
        <Button variant="outlined" onClick={() => setAbsenceModalOpen(true)}>Lån ut</Button>
      </Box>
      {editLoan && (
        <Modal title={`Utlån av ${worker?.fullName} (${worker?.employeeNumber})`} maxWidth="sm" onClose={() => setEditLoanId(undefined)}>
          <EditLoan loan={editLoan} onClose={() => setEditLoanId(undefined)} />
        </Modal>
      )}
      {absenceModalOpen && (
      <Modal title={`Lån ut ${worker?.fullName} (${worker?.employeeNumber})`} maxWidth="sm" onClose={closeModal}>
        <EditLoan userId={worker?.employeeNumber} onClose={closeModal} startDate={isBefore(date, new Date()) ? new Date() : date} />
      </Modal>
      )}
    </Box>
  );
};
