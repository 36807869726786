/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SelectItem } from '../../shared/types/util/selectItem';
import { PageName } from '../types/util/pageName';

const stateName = 'leftFilterState';

const projectColumns = [
  'id',
  'responsible_id',
  'responsible_name',
] as const;
type ProjectColumn = typeof projectColumns[number];

const workerColumns = [
  'id',
  'employmentType',
  'department',
  'jobRole',
  'leader',
  'tag',
] as const;
type WorkerColumn = typeof workerColumns[number];

export const sortOptions = [
  'stucture', // Default sorting
  'id', // Employee ID
  'name', // Employee name
  'mark', // Tag
  'project', // Sort by active or upcoming project
] as const;

export type SortOptions = typeof sortOptions[number];

interface InitialState {
  project: {
    columns: Array<ProjectColumn>,
    managers: SelectItem[],
    search: string,
    includeInternalProduction: boolean,
    includeOwnProjects: boolean,
    showOnlyPlannedProjects: boolean,
  },
  worker: {
    leaders: SelectItem[],
    search: string,
    employmentTypes: SelectItem[],
    jobRoles: SelectItem[],
    columns: Array<WorkerColumn>,
    sortBy: SortOptions,
  },
  status: { columns: undefined },
  organizations: Array<number>,
  defaultSet: boolean,
}

const initialState: InitialState = {
  project: {
    columns: ['id'],
    managers: [],
    search: '',
    includeInternalProduction: false,
    includeOwnProjects: true,
    showOnlyPlannedProjects: false,

  },
  worker: {
    leaders: [],
    search: '',
    employmentTypes: [],
    jobRoles: [],
    columns: ['id'],
    sortBy: 'stucture',
  },
  status: {
    columns: undefined,
  },
  organizations: [],
  defaultSet: false,
};

const leftFilterState = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setShowOnlyPlannedProjects(state, { payload: showOnlyPlannedProjects }: PayloadAction<boolean>) {
      state.project.showOnlyPlannedProjects = showOnlyPlannedProjects;
    },
    setIncludeInternalProduction(state, { payload: includeInternalProduction }: PayloadAction<boolean>) {
      state.project.includeInternalProduction = includeInternalProduction;
    },
    setIncludeOwnProjects(state, { payload: includeOwnProjects }: PayloadAction<boolean>) {
      state.project.includeOwnProjects = includeOwnProjects;
    },
    setProjectSearch(state, { payload: search }: PayloadAction<string>) {
      state.project.search = search;
    },
    setProjectManagers(state, { payload: managers }: PayloadAction<SelectItem[]>) {
      state.project.managers = managers;
    },
    setDefaultOrganization(state, { payload: org }: PayloadAction<number>) {
      if (!state.defaultSet) {
        state.defaultSet = true;
        if (!state.organizations.some((o) => o === org)) {
          state.organizations = [...state.organizations, org];
        }
      }
    },
    setWorkerSearch(state, { payload: search }: PayloadAction<string>) {
      state.worker.search = search;
    },
    setWorkerLeaders(state, { payload: leaders }: PayloadAction<SelectItem[]>) {
      state.worker.leaders = leaders;
    },
    setWorkerEmploymentTypes(state, { payload: employmentTypes }: PayloadAction<SelectItem[]>) {
      state.worker.employmentTypes = employmentTypes;
    },
    setWorkerJobRoles(state, { payload: jobRoles }: PayloadAction<SelectItem[]>) {
      state.worker.jobRoles = jobRoles;
    },
    setColumns(state, { payload: { project, worker } }: PayloadAction<{project?: ProjectColumn[], worker?: WorkerColumn[]}>) {
      if (project) {
        state.project.columns = project.filter((column: string) => column !== 'label');
      }
      if (worker) {
        state.worker.columns = worker.filter((column: string) => column !== 'label');
      }
    },
    setSortBy(state, { payload }: PayloadAction<typeof state.worker.sortBy>) {
      state.worker.sortBy = payload;
    },
    setOrganizations(state, { payload: organizations }: PayloadAction<number[]>) {
      state.organizations = organizations;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

const sortOrder = [
  'id',
  'label',
  'responsible_id',
  'responsible_name',
  'employmentType',
  'department',
  'jobRole',
  'leader',
  'tag',
];

export const useColumns = (type: PageName) => {
  const state = useSelector((s: {[stateName]: InitialState}) => s[stateName][type].columns);
  const sorted = useMemo(() => {
    if (state === undefined) return [];
    const cols = [...state] as string[];
    cols.push('label');
    cols.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    return cols;
  }, [state]);
  return sorted;
};

export const useOrganizations = () => {
  const organizations = useSelector((s: {[stateName]: InitialState}) => s[stateName].organizations);
  return organizations;
};

export const useColumnNames = (type: PageName) => {
  if (type === 'project') {
    return projectColumns;
  }
  return workerColumns;
};

export const {
  setShowOnlyPlannedProjects,
  setIncludeInternalProduction,
  setIncludeOwnProjects,
  setProjectSearch,
  setWorkerLeaders,
  setProjectManagers,
  setWorkerSearch,
  setWorkerEmploymentTypes,
  setWorkerJobRoles,
  setColumns,
  setSortBy,
  setOrganizations,
  setDefaultOrganization,
  reset,
} = leftFilterState.actions;

export default persistReducer({ key: stateName, storage }, leftFilterState.reducer);
