export const calendarViews = ['dag', 'uke', 'maned', 'kvartal', 'ar'] as const;

export type CalendarView = typeof calendarViews[number];

export const view: {
  day: CalendarView,
  week: CalendarView,
  month: CalendarView,
  quarter: CalendarView,
  year: CalendarView,
} = {
  day: 'dag',
  week: 'uke',
  month: 'maned',
  quarter: 'kvartal',
  year: 'ar',
};
