import { FC, useMemo, useState } from 'react';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getAttributeSet } from '../../../core/helpers/functions';
import { PageName } from '../../../core/types/util/pageName';
import { useView } from '../../../core/hooks/useView';
import { Favourite } from '../../../shared/components/favourite';
import { Displayable } from '../../../shared/types/util/displayable';
import { ExpiredCert } from '../../../shared/components/certification/expiredCert';
import { MissingSecTraining } from '../../../shared/components/securityTraining/missingSecTraining';

export const SidebarItem: FC<{
  page: PageName;
  item: Displayable;
  onClick: () => void;
}> = ({ page, item, onClick }) => {
  const [view] = useView(page);

  const dur = useMemo(() => {
    if (view === 'dag') return '01:00';
    if (view === 'uke') return '08:00';
    if (view === 'maned') return '24:00';
    if (view === 'kvartal') return '120:00';
    if (view === 'ar') return '120:00';
    return '08:00';
  }, [view]);

  const attributes = getAttributeSet({
    isMachine: `${item.id}`.includes('machine'),
    isEquipment: `${item.id}`.includes('equipment'),
    title: item.label,
    workertype:
      typeof item.id === 'string' || item.id < 0 ? item.label : undefined,
  });

  const [expanded, setExpanded] = useState<boolean>(false);

  const subs = item.subCategories?.map((s) => (
    <div
      className="task-item sub-item"
      data-title={s}
      data-id={s}
      data-duration={dur}
      key={`sub-category-${s}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getAttributeSet({
        isMachine: `${item.id}`.includes('machine'),
        isEquipment: `${item.id}`.includes('equipment'),
        title: s,
        workertype: item.label,
      })}
      draggable
    >
      <Card
        className="sub-card"
        sx={{
          paddingTop: 0.2,
          paddingBottom: 0.2,
          marginBottom: 0.1,
        }}
        key={s}
      >
        <div className="line-wrapper">
          <span className="header one-line-ellipse sub-category">{s}</span>
        </div>
      </Card>
    </div>
  ));

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {item.subCategories ? (
        <>
          <div
            className="task-item"
            data-title={item.label}
            data-id={item.label}
            data-duration={dur}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attributes}
            draggable
          >
            <Card
              sx={{
                backgroundColor: `${item.id}`.includes('machine') ? '#d7dfec' : '#d7ece2',
              }}
            >
              <CardActionArea onClick={() => setExpanded(!expanded)}>
                <div className="line-wrapper">
                  <span className="header one-line-ellipse">{item.label}</span>
                  {expanded ? (
                    <ExpandLessIcon sx={{ fontSize: '19px' }} />
                  ) : (
                    <ExpandMoreIcon sx={{ fontSize: '19px' }} />
                  )}
                </div>
              </CardActionArea>
            </Card>
          </div>
          {expanded && subs}
        </>
      ) : (
        <div
          className="task-item"
          data-title={item.label}
          data-id={item.id < 0 ? item.label : item.id}
          data-duration={dur}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
          draggable
        >
          <Card
            sx={{
              backgroundColor: item.id < 0 ? '#f0f0f0' : 'ffffff',
            }}
          >
            <CardActionArea onClick={item.id < 0 ? undefined : onClick}>
              <div className="line-wrapper">
                <span className="header one-line-ellipse">{item.label}</span>
                <span style={{ display: 'flex', gap: '10px', marginRight: '5px' }}>
                  {page === 'project' && item.expiredCertifications ? (
                    <ExpiredCert
                      expCert={item.expiredCertifications}
                    />
                  ) : null}
                  {page === 'project' && item.missingSecurityTraining ? (
                    <MissingSecTraining
                      expSecTraining={item.missingSecurityTraining}
                    />
                  ) : null}
                  {item.starred === true && (
                    <span>
                      <Favourite icon />
                    </span>
                  )}
                </span>
              </div>
            </CardActionArea>
          </Card>
        </div>
      )}
    </>
  );
};
